import { Like } from '@prisma/client';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';

import { CreateLike } from 'types/models/Assignment';

const createLike = async ({ entryId, entryType, messageId }: CreateLike) => {
  const { data } = await axiosInstance.post<{ like: Like[] }>(
    Routes.API.LIKES,
    {
      entryId,
      entryType,
      messageId,
    }
  );
  return data;
};

export const useCreateLike = () => {
  const mutation = useMutation<
    { like: Like[] },
    AxiosError,
    CreateLike,
    unknown
  >({
    mutationFn: (data: CreateLike) => createLike(data),
    throwOnError: false,
  });

  return mutation;
};
