import Button from '@/components/Button';
import { Dialog, DialogClose, DialogContent } from '@/components/Dialog';
import { useTour } from '@reactour/tour';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useHasSeenOnboarding } from '../react-query/users/useHasSeenOnborading';
import { useWindowSize } from './useWindowSize';

export interface OnBoardingStep {
  step: number;
  selector: string;
  content: string | JSX.Element;
  route: string;
  action?: (elem: Element | null) => void;
}

export interface OnBoardingContextProps {
  WelcomeTourModal: () => JSX.Element;
  ConfirmSkipTourModal: () => JSX.Element;
}

export const onBoardingDesktopSteps: OnBoardingStep[] = [
  {
    step: 1,
    selector: `[aria-label="Desktop-Dashboard-Icon"]`,
    content: (
      <div className="text-sm">
        {' '}
        <div className="p-1">
          {' '}
          When you first log in, you will arrive at the Dashboard. The Dashboard
          has a checklist of the things you need to do to create your first
          assignment.
        </div>
      </div>
    ),
    route: '/dashboard',
  },
  {
    step: 2,
    selector: '[aria-label="Desktop-Classrooms-Icon"]',
    content: (
      <div className="text-sm">
        <div className="p-1">
          The Classrooms area is where you can create classes, add students, and
          create student groups.
        </div>
      </div>
    ),
    route: '/classrooms',
  },
  {
    step: 3,
    selector: '[aria-label="Desktop-Tasks-Icon"]',
    content: (
      <div className="text-sm">
        <div className="p-1">
          This area is for Tasks. You can think of Tasks like a template for
          your Assignments. You’ll want to create one or more tasks before you
          start to create an Assignment.
        </div>
      </div>
    ),
    route: '/tasks',
  },
  {
    step: 4,
    selector: '[aria-label="Desktop-Assignments-Icon"]',
    content: (
      <div className="text-sm">
        <div className="p-1">
          The Assignments section is where you can add and view your
          assignments. Once students get started on an assignment, you will be
          able to monitor and review their work.{' '}
          <div className="py-1">
            {' '}
            That’s the tour! You can find a full walkthrough on the{' '}
            <Link href={'/instructions'} className="underline" target="_blank">
              {' '}
              Instructions
            </Link>{' '}
            page.
          </div>
        </div>
      </div>
    ),
    route: '/assignments',
  },
];

export const onBoardingMobileSteps: OnBoardingStep[] = [
  {
    step: 1,
    selector: `[aria-label="Mobile-Dashboard-Icon"]`,
    content: (
      <div className="text-sm">
        <div className="p-1">
          When you first log in, you will arrive at the Dashboard. The Dashboard
          has a checklist of the things you need to do to create your first
          assignment.
        </div>
      </div>
    ),
    route: '/dashboard',
  },
  {
    step: 2,
    selector: '[aria-label="Mobile-Classrooms-Icon"]',
    content: (
      <div className="text-sm">
        <div className="p-1">
          The Classrooms area is where you can create classes, add students, and
          create student groups.
        </div>
      </div>
    ),
    route: '/classrooms',
  },
  {
    step: 3,
    selector: '[aria-label="Mobile-Tasks-Icon"]',
    content: (
      <div className="text-sm">
        <div className="p-1">
          This area is for Tasks. You can think of Tasks like a template for
          your Assignments. You’ll want to create one or more tasks before you
          start to create an Assignment.
        </div>
      </div>
    ),
    route: '/tasks',
  },
  {
    step: 4,
    selector: '[aria-label="Mobile-Assignments-Icon"]',
    content: (
      <div className="text-sm">
        <div className="p-1">
          The Assignments section is where you can add and view your
          assignments. Once students get started on an assignment, you will be
          able to monitor and review their work.{' '}
          <div className="py-1">
            {' '}
            That’s the tour! You can find a full walkthrough on the{' '}
            <Link href={'/instructions'} className="underline" target="_blank">
              {' '}
              Instructions
            </Link>{' '}
            page.
          </div>
        </div>
      </div>
    ),
    route: '/assignments',
  },
];

const OnBoardingContext = createContext<OnBoardingContextProps>({
  WelcomeTourModal: () => <></>,
  ConfirmSkipTourModal: () => <></>,
});

export const useOnBoarding = () => useContext(OnBoardingContext);

export const OnBoardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [welcomeTourState, setWelcomeTourState] = useState({
    isOpen: false,
  });
  const [skipTourState, setSkipTourState] = useState({
    isOpen: false,
  });
  const { data } = useSession();

  const { setIsOpen, setSteps } = useTour();
  const { width } = useWindowSize();

  const { mutate: hasSeenOnboarding } = useHasSeenOnboarding();

  useEffect(() => {
    if (data?.user.hasSeenOnBoarding) {
      setIsOpen(false);
    }
    if (welcomeTourState.isOpen) {
      setIsOpen(false);
    }
    if (data?.user.role === 'TEACHER' && !data?.user.hasSeenOnBoarding) {
      setWelcomeTourState({ isOpen: true });
    } else {
      setWelcomeTourState({ isOpen: false });
    }
    const isMobile = (width ?? 0) < 768;
    if (!setSteps) return;
    setSteps(() => (isMobile ? onBoardingMobileSteps : onBoardingDesktopSteps));
  }, [width, data?.user.role, data?.user.hasSeenOnBoarding]);

  const WelcomeTourModal = () => {
    return (
      <Dialog
        open={welcomeTourState.isOpen}
        onOpenChange={(isOpen) => {
          setWelcomeTourState({ isOpen });
        }}
      >
        <DialogContent
          enableOverflow={true}
          size="sm"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogClose
            className="absolute right-1 top-2  rounded-full"
            onClick={() => {
              setIsOpen(false);
              setSkipTourState({ isOpen: true });
            }}
          >
            <FiX size={24} className="cursor-pointer" />
          </DialogClose>
          <div className="flex flex-col items-center text-center font-bold">
            <div className="flex py-2"> Welcome to MindLabs!</div>{' '}
            <div className="flex ">{`Let's take a quick tour of the system.`}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex">
              <Button
                className="underline"
                color="minimal"
                onClick={async () => {
                  setWelcomeTourState({ isOpen: false });
                  setSkipTourState({ isOpen: true });
                }}
              >
                Skip Tutorial
              </Button>
            </div>
            <div className="flex">
              <Button
                onClick={async () => {
                  setWelcomeTourState({ isOpen: false });
                  setSkipTourState({ isOpen: false });
                  setIsOpen(true);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const ConfirmSkipTourModal = () => {
    return (
      <Dialog
        open={skipTourState.isOpen}
        onOpenChange={(isOpen) => {
          setSkipTourState({ isOpen });
        }}
      >
        <DialogContent
          enableOverflow={true}
          size="sm"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogClose
            className="absolute right-1 top-2 rounded-full"
            onClick={() => {
              setIsOpen(false);
              setWelcomeTourState({ isOpen: false });
              setSkipTourState({ isOpen: false });
              hasSeenOnboarding({ hasSeenOnBoarding: true });
            }}
          >
            <FiX size={24} className="cursor-pointer" />
          </DialogClose>
          <div className="flex flex-col text-center font-bold text-sm">
            <div className="py-2">
              {' '}
              {`Are you sure you want to leave the tour? You won't be able to return, but you will always have access to the`}{' '}
              <Link className="underline" href={'/instructions'}>
                Instructions
              </Link>{' '}
              {`page`}.
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex">
              <Button
                className="underline"
                color="minimal"
                onClick={async () => {
                  setWelcomeTourState({ isOpen: true });
                  setSkipTourState({ isOpen: false });
                }}
              >
                Return to the tour
              </Button>
            </div>
            <div className="flex">
              <Button
                onClick={async () => {
                  setIsOpen(false);
                  setWelcomeTourState({ isOpen: false });
                  setSkipTourState({ isOpen: false });
                  hasSeenOnboarding({ hasSeenOnBoarding: true });
                }}
              >
                Skip Tour
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <OnBoardingContext.Provider
      value={{
        WelcomeTourModal,
        ConfirmSkipTourModal,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};
