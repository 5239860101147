import { useQuery } from '@tanstack/react-query';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';
import { filterEmptyKeys } from 'lib/react-query/queryKeys';
import { TextLabelSetWithTextLabels } from 'types/models/NotebookTemplate';

const getTextLabels = async (includeDefaultText?: boolean) => {
  const { data } = await axiosInstance.get<TextLabelSetWithTextLabels[]>(
    `${Routes.API.TEXT_LABELS}${includeDefaultText ? '?includeDefaultText=true' : ''}`
  );
  return data;
};

export const useTextLabels = ({
  queryKey,
  teacherId,
  includeDefaultText,
}: {
  queryKey: (string | number)[];
  teacherId?: string;
  includeDefaultText?: boolean;
}) => {
  const query = useQuery({
    queryKey: filterEmptyKeys(queryKey),
    queryFn: () => getTextLabels(includeDefaultText),

    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    enabled: !!teacherId,
  });

  return query;
};
