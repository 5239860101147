import { TextLabelSet } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';
import { filterEmptyKeys } from 'lib/react-query/queryKeys';

const getTextLabels = async () => {
  const { data } = await axiosInstance.get<TextLabelSet[]>(
    Routes.API.TEXT_LABELS
  );
  return data;
};

export const useTasksTextLabels = ({
  queryKey,
  enabled,
}: {
  queryKey: (string | number)[];

  enabled: boolean;
}) => {
  const query = useQuery({
    queryKey: filterEmptyKeys(queryKey),
    queryFn: () => getTextLabels(),
    throwOnError: false,
    staleTime: 1000 * 60 * 60 * 1, // 1 hours
    enabled,
  });

  return query;
};
