import { EngineeringNotebookStepField, TextLabel } from '@prisma/client';
import { z } from 'zod';
export interface NotebookText {
  key: string;
  defaultText: string;
}
export const defaultExploreNotebookTexts: NotebookText[] = [
  {
    key: 'Notebook_General_ConfirmButton',
    defaultText: 'Yes',
  },
  {
    key: 'Notebook_General_DenyButton',
    defaultText: 'No',
  },
  {
    key: 'Notebook_GuidePage_TabLabel',
    defaultText: 'Guide',
  },
  {
    key: 'Notebook_GuidePage_TitleLeft',
    defaultText: 'How do I...',
  },
  {
    key: 'Notebook_GuidePage_TitleRight',
    defaultText: 'Design Process',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: 'Ask',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: 'Imagine',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: 'Plan',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: 'Create',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: 'Test',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      'Engineers are people who design solutions to problems. They follow a process to design solutions. To start the process, an engineer observes a problem, a need, or a want. The engineer must ASK questions of others and REFLECT on their answers to discover evidence, criteria, and constraints to design the best solution.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      "Engineers use their creativity when they IMAGINE many different solutions to solve a problem. Don't stop imagining after your first idea! Brainstorming with others can help engineers consider other alternatives that may lead to a better solution!",
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      'To plan the design solution, engineers must decide which idea to try first. Consider which ideas meet your criteria and constraints - each option likely has tradeoffs. First, your group needs to make a decision. Next, your solution will sometimes have many parts that will need to work together. Make a plan to break down your design into smaller steps or pieces that you can test.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      "Engineers often CREATE digital and physical prototypes before they CREATE their final solution. A prototype is a model of the proposed solution that can be used to test the design. Prototypes are faster, easier, and cheaper to change if your first try doesn't meet all of your criteria and constraints.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      'Engineers TEST their prototypes to check how they perform. Most prototypes do not perform perfectly the first time, even for professional engineers! Running a TEST allows engineers to observe and collect evidence on whether the solution meets the criteria and constraints.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      'Engineers REFLECT on their test RESULTS and iterate on their designs many times by repeating the steps of Create and Test to IMPROVE their solutions. They may even take their prototype to the people who will use the solution to ASK if the proposed design meets their criteria and constraints.',
  },
  {
    key: 'Notebook_ProblemPage_TabLabel',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_ProblemPage_TitleLeft',
    defaultText: 'The Problem!',
  },
  {
    key: 'Notebook_ProblemPage_TitleRight',
    defaultText: 'Success Checklist',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentName',
    defaultText: 'Your Mission',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDateLabel',
    defaultText: 'None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: 'Due Date: None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      'Engineers use the Engineering Design Process to solve problems. You can learn more about it in the Guide tab.\n\nUse this notebook to document your process!',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaTitle',
    defaultText: 'Criteria',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintTitle',
    defaultText: 'Constraints',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText: 'Write your criteria for success out here...',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText: 'Write your constraints out here...',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: 'Submit Your Ideas!',
  },
  {
    key: 'Notebook_ResultsPage_TabLabel',
    defaultText: 'Results',
  },
  {
    key: 'Notebook_ResultsPage_TitleLeft',
    defaultText: 'Results!',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Title',
    defaultText: 'Observe',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText:
      'What were the results of this design test? How are your results different or similar to what you predicted?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Title',
    defaultText: 'Explain',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      "How can you explain to others what you discovered was why the design didn't work as you predicted?",
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText: 'How might you fix your design for a better result?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: 'Are you finished testing?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: 'Submit your work?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText:
      "Are you sure you're ready to submit? You cannot make changes once you submit.",
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: 'Submit Test Notes',
  },
  {
    key: 'Notebook_ReflectPage_TabLabel',
    defaultText: 'Reflect',
  },
  {
    key: 'Notebook_ReflectPage_TitleLeft',
    defaultText: 'I wanted to share...',
  },
  {
    key: 'Notebook_ReflectPage_TitleRight',
    defaultText: 'Reflection',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: 'A connection I made is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: 'Something I discovered is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: 'A question I had is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: 'I am confused by',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: 'An idea I thought of is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: 'A feeling I had is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: 'Submit Your Ideas!',
  },
];

export const defaultExploreNotebookTextsKorean: NotebookText[] = [
  {
    key: 'Notebook_General_ConfirmButton',
    defaultText: '예',
  },
  {
    key: 'Notebook_General_DenyButton',
    defaultText: '아니요',
  },
  {
    key: 'Notebook_GuidePage_TabLabel',
    defaultText: '가이드',
  },
  {
    key: 'Notebook_GuidePage_TitleLeft',
    defaultText: '내가 어떻게 할...',
  },
  {
    key: 'Notebook_GuidePage_TitleRight',
    defaultText: '디자인 과정',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: '묻다',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: '상상하다',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: '계획',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: '만들다',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: '시험',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: '개선하다',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      '엔지니어는 문제에 대한 해결책을 설계하는 사람입니다. 그들은 솔루션을 설계하는 프로세스를 따릅니다. 프로세스를 시작하기 위해 엔지니어는 문제, 요구 사항 또는 원하는 것을 관찰합니다. 엔지니어는 최상의 솔루션을 설계하기 위한 증거, 기준 및 제약 조건을 발견하기 위해 다른 사람에게 질문하고 답변을 반영해야 합니다.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      '엔지니어는 문제를 해결하기 위해 다양한 솔루션을 상상할 때 창의력을 발휘합니다. 첫 번째 아이디어 이후에도 상상을 멈추지 마세요! 다른 사람들과 브레인스토밍하면 엔지니어가 더 나은 솔루션으로 이어질 수 있는 다른 대안을 고려하는 데 도움이 될 수 있습니다.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      '설계 솔루션을 계획하려면 엔지니어는 어떤 아이디어를 먼저 시도할지 결정해야 합니다. 어떤 아이디어가 기준과 제약 조건을 충족하는지 고려하세요. 각 옵션에는 장단점이 있을 수 있습니다. 먼저, 그룹은 결정을 내려야 합니다. 다음으로 솔루션에는 함께 작동해야 하는 많은 부분이 있을 수 있습니다. 디자인을 테스트할 수 있는 작은 단계나 조각으로 나누는 계획을 세우세요.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      '엔지니어는 최종 솔루션을 만들기 전에 디지털 및 실제 프로토타입을 만드는 경우가 많습니다. 프로토타입은 설계를 테스트하는 데 사용할 수 있는 제안된 솔루션의 모델입니다. 첫 번째 시도가 모든 기준과 제약 사항을 충족하지 못하는 경우 프로토타입을 변경하는 것이 더 빠르고 쉽고 저렴합니다.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      '엔지니어는 프로토타입을 테스트하여 성능을 확인합니다. 대부분의 프로토타입은 전문 엔지니어라도 처음에는 완벽하게 작동하지 않습니다. 테스트를 실행하면 엔지니어는 솔루션이 기준 및 제약 조건을 충족하는지 여부에 대한 증거를 관찰하고 수집할 수 있습니다.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      '엔지니어는 테스트 결과를 반영하고 솔루션을 개선하기 위해 생성 및 테스트 단계를 반복하여 설계를 여러 번 반복합니다. 제안된 디자인이 기준과 제약 조건을 충족하는지 물어보기 위해 솔루션을 사용할 사람들에게 프로토타입을 가져갈 수도 있습니다.',
  },
  {
    key: 'Notebook_ProblemPage_TabLabel',
    defaultText: '문제',
  },
  {
    key: 'Notebook_ProblemPage_TitleLeft',
    defaultText: '문제!',
  },
  {
    key: 'Notebook_ProblemPage_TitleRight',
    defaultText: '성공 체크리스트',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentName',
    defaultText: '당신의 임무',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: '없음!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDateLabel',
    defaultText: '마감일: 없음!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      '엔지니어는 엔지니어링 설계 프로세스를 사용하여 문제를 해결합니다. 가이드 탭에서 자세한 내용을 알아볼 수 있습니다. 이 노트를 사용하여 프로세스를 문서화하세요!',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaTitle',
    defaultText: '기준',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintTitle',
    defaultText: '제약',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText: '여기에 성공의 기준을 적어보세요...',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText: '여기에 제약 조건을 작성하세요...',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: '당신의 아이디어를 제출하세요!',
  },
  {
    key: 'Notebook_ResultsPage_TabLabel',
    defaultText: '결과',
  },
  {
    key: 'Notebook_ResultsPage_TitleLeft',
    defaultText: '결과!',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Title',
    defaultText: '관찰하다',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText:
      '이번 디자인 테스트 결과는 어땠나요? 결과가 예측한 것과 어떻게 다르거나 유사합니까?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Title',
    defaultText: '설명하다',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      '디자인이 예상대로 작동하지 않은 이유를 발견한 것을 다른 사람들에게 어떻게 설명할 수 있습니까?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Title',
    defaultText: '개선하다',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText: '더 나은 결과를 위해 디자인을 어떻게 수정할 수 있습니까?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText: '여기에 당신의 아이디어를 적어보세요...',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: '테스트는 끝났나요?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: '작품을 제출하시겠습니까?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText: '제출할 준비가 되셨나요? 제출한 후에는 변경할 수 없습니다.',
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: '테스트 노트 제출',
  },
  {
    key: 'Notebook_ReflectPage_TabLabel',
    defaultText: '반영하다',
  },
  {
    key: 'Notebook_ReflectPage_TitleLeft',
    defaultText: '공유하고 싶었는데...',
  },
  {
    key: 'Notebook_ReflectPage_TitleRight',
    defaultText: '반사',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: '내가 만든 연결은',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: '제가 발견한 것은',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: '내가 가졌던 질문은',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: '나는 혼란스러워',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: '제가 생각한 아이디어는',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: '내가 느낀 느낌은',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: '여기에 당신의 아이디어를 적어보세요...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: '당신의 아이디어를 제출하세요!',
  },
];

export const defaultBostonNotebookTexts: NotebookText[] = [
  {
    key: 'Notebook_General_ConfirmButton',
    defaultText: 'Yes',
  },
  {
    key: 'Notebook_General_DenyButton',
    defaultText: 'No',
  },
  {
    key: 'Notebook_GuidePage_TabLabel',
    defaultText: 'Guide',
  },
  {
    key: 'Notebook_GuidePage_TitleLeft',
    defaultText: 'How do I...',
  },
  {
    key: 'Notebook_GuidePage_TitleRight',
    defaultText: 'Design Process',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: 'Ask',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: 'Imagine',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: 'Plan',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: 'Create',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: 'Test',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      'Engineers are people who thoughtfully use a process to design something to solve a problem. Engineers often begin by ASKing questions to learn about the problem they are trying to solve. This helps them to identify criteria and constraints.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      'Engineers use what they learned in the Ask phase to IMAGINE solutions.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      'Engineers use their best ideas and work effectively in teams to PLAN one design to create and test.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      'Engineers often CREATE prototypes to test their ideas before finalizing a design.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      'Engineers TEST their prototypes to see how well their solution meets the criteria and constraints. ',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      'Engineers learn from failure and IMPROVE their designs based on evidence from their test results.',
  },
  {
    key: 'Notebook_ProblemPage_TabLabel',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_ProblemPage_TitleLeft',
    defaultText: 'The Problem!',
  },
  {
    key: 'Notebook_ProblemPage_TitleRight',
    defaultText: 'Design Challenge Chart',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentName',
    defaultText: 'Your Goal',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDateLabel',
    defaultText: '',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: 'Due Date: None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      'Engineers use the Engineering Design Process to solve problems. You can learn more about it in the Guide tab.\n\nUse this notebook to document your process!',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaTitle',
    defaultText: 'Criteria',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintTitle',
    defaultText: 'Constraints',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText: 'Write your criteria for success out here...',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText: 'Write your constraints out here...',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: 'Share Your Ideas',
  },
  {
    key: 'Notebook_ResultsPage_TabLabel',
    defaultText: 'Results',
  },
  {
    key: 'Notebook_ResultsPage_TitleLeft',
    defaultText: 'Results!',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Title',
    defaultText: 'Observe',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText: 'What were the results of the test?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Title',
    defaultText: 'Explain',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText: 'Why do you think your design performed the way it did?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText: 'How will you improve your design? Why?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: 'Are you finished testing?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: 'Submit your work?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText:
      "Are you sure you're ready to submit? You cannot make changes once you submit.",
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: 'Submit Test Notes',
  },
  {
    key: 'Notebook_ReflectPage_TabLabel',
    defaultText: 'Reflect',
  },
  {
    key: 'Notebook_ReflectPage_TitleLeft',
    defaultText: 'I wanted to share...',
  },
  {
    key: 'Notebook_ReflectPage_TitleRight',
    defaultText: 'Reflection',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: 'A connection I made is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: 'I learned that',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: 'A question I had is',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: 'I am confused by',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: 'I want to get better at',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: 'My favorite part was',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: 'Submit Your Ideas!',
  },
];

export const roboticsProblemNotebookTexts: NotebookText[] = [
  {
    key: 'Notebook_General_ConfirmButton',
    defaultText: 'Yes',
  },
  {
    key: 'Notebook_General_DenyButton',
    defaultText: 'No',
  },
  {
    key: 'Notebook_GuidePage_TabLabel',
    defaultText: 'Guide',
  },
  {
    key: 'Notebook_GuidePage_TitleLeft',
    defaultText: 'Engineering Design',
  },
  {
    key: 'Notebook_GuidePage_TitleRight',
    defaultText: 'Process',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: 'Brainstorm',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: 'Plan',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: 'Build',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: 'Test',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      "What - Identify the design challenges in detail at the start of each design process cycle with words and pictures. States the goals for accomplishing the challenge.\n\nWhy - It's important to understand the problem you are trying to solve to know what needs to be fixed or improved, and design the best solution.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      'What - Use this notebook to capture the possible solutions to the challenge with labeled diagrams. Provide citations for ideas that came from outside sources such as online videos or other teams.\n\nWhy - Brainstorming is an important step in the design process because a diversity of ideas will lead to a better solution.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      "What - Explain why the solution was selected through testing and/or a decision matrix. Fully describe the plan to implement the solution.\n\nWhy - It's important to explain the steps of your plan to solve the problem because it helps others understand what you're doing and makes sure everyone agrees on the best way to fix the problem.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      "What - Record the steps to build and program the solution. Include enough detail that the reader can follow the logic used by the team to develop their design, as well as recreate the design from the documentation.\n\nWhy - It's important to explain the steps in detail because it helps others understand how your design works, lets them follow the same process to build it themselves, and makes it easier to fix or improve the design in the future.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      "What - Record all the steps to test the solution, including test results.\n\nWhy - It's important to record the steps to test the solution because it shows whether your solution works as planned, helps you find and fix any problems, and lets others see how well your design performs.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      "What - Show that the design process is repeated multiple times to improve performance on a design goal, or robot/game performance.\n\nWhy - It's important to show that the design process has been repeated multiple times because each time you make improvements, your design gets better and works more like you want it to. This helps you create the best solution possible.",
  },
  {
    key: 'Notebook_ProblemPage_TabLabel',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_ProblemPage_TitleLeft',
    defaultText: 'Information',
  },
  {
    key: 'Notebook_ProblemPage_TitleRight',
    defaultText: 'Team Notes',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentName',
    defaultText: 'Problem Identification',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: 'None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      'Research the problem you are working on. Look for the criteria for success and the constraints. What other information is important to know?',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaTitle',
    defaultText: 'Goals',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintTitle',
    defaultText: 'Roles',
  },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText: 'What are the important goals for this phase of your work?',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText:
      'What is your role in this phase? What responsibilities do you have in your role?',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: 'Submit Your Ideas!',
  },
  {
    key: 'Notebook_ResultsPage_TabLabel',
    defaultText: 'Results',
  },
  {
    key: 'Notebook_ResultsPage_TitleLeft',
    defaultText: 'Log!',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Title',
    defaultText: 'DESCRIBE',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText:
      'What part of the problem are you investigating? What are the criteria and constraints? Take pictures of any ideas that you use.',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Title',
    defaultText: 'INVESTIGATE',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      'What additional information or ideas do you have from your research and investigation into this part of the challenge?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Title',
    defaultText: 'CITE',
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText:
      'What are the sources you used for your information and ideas?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText:
      'Record your answers here. Be sure to restate the questions you are answering to have a complete record of your thoughts.',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: 'Ready to turn in your work?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: 'Log your thoughts',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText:
      "Are you sure you're ready to submit? You cannot make changes once you submit.",
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: 'Submit Your Results!',
  },
  {
    key: 'Notebook_ReflectPage_TabLabel',
    defaultText: 'Reflect',
  },
  {
    key: 'Notebook_ReflectPage_TitleLeft',
    defaultText: 'What I learned...',
  },
  {
    key: 'Notebook_ReflectPage_TitleRight',
    defaultText: 'Reflection',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: 'About myself is....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: 'About working with a team is...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: 'About the Engineering Design Process was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: 'The biggest challenge was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: 'One of our successes was ....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: 'I wish that.........',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: 'Submit Your Ideas!',
  },
];

export const roboticsBrainstormNotebookTexts: NotebookText[] = [
  { key: 'Notebook_General_ConfirmButton', defaultText: 'Yes' },
  { key: 'Notebook_General_DenyButton', defaultText: 'No' },
  { key: 'Notebook_GuidePage_TabLabel', defaultText: 'Guide' },
  { key: 'Notebook_GuidePage_TitleLeft', defaultText: 'Engineering Design' },
  { key: 'Notebook_GuidePage_TitleRight', defaultText: 'Process' },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: 'Brainstorm',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: 'Plan',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: 'Build',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: 'Test',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      "What - Identify the design challenges in detail at the start of each design process cycle with words and pictures. States the goals for accomplishing the challenge.\n\nWhy - It's important to understand the problem you are trying to solve to know what needs to be fixed or improved, and design the best solution.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      'What - Use this notebook to capture the possible solutions to the challenge with labeled diagrams. Provide citations for ideas that came from outside sources such as online videos or other teams.\n\nWhy - Brainstorming is an important step in the design process because a diversity of ideas will lead to a better solution.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      "What - Explain why the solution was selected through testing and/or a decision matrix. Fully describe the plan to implement the solution.\n\nWhy - It's important to explain the steps of your plan to solve the problem because it helps others understand what you're doing and makes sure everyone agrees on the best way to fix the problem.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      "What - Record the steps to build and program the solution. Include enough detail that the reader can follow the logic used by the team to develop their design, as well as recreate the design from the documentation.\n\nWhy - It's important to explain the steps in detail because it helps others understand how your design works, lets them follow the same process to build it themselves, and makes it easier to fix or improve the design in the future.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      "What - Record all the steps to test the solution, including test results.\n\nWhy - It's important to record the steps to test the solution because it shows whether your solution works as planned, helps you find and fix any problems, and lets others see how well your design performs.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      "What - Show that the design process is repeated multiple times to improve performance on a design goal, or robot/game performance.\n\nWhy - It's important to show that the design process has been repeated multiple times because each time you make improvements, your design gets better and works more like you want it to. This helps you create the best solution possible.",
  },
  { key: 'Notebook_ProblemPage_TabLabel', defaultText: 'Problem' },
  { key: 'Notebook_ProblemPage_TitleLeft', defaultText: 'Information' },
  { key: 'Notebook_ProblemPage_TitleRight', defaultText: 'Team Notes' },
  { key: 'Notebook_ProblemPage_AssignmentName', defaultText: 'Brainstorm' },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: 'None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      'Brainstorming is an important step in the design process. Use this notebook to capture the possible solutions to the challenge with labeled diagrams.',
  },
  { key: 'Notebook_ProblemPage_CriteriaTitle', defaultText: 'Goals' },
  { key: 'Notebook_ProblemPage_ConstraintTitle', defaultText: 'Roles' },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText: 'What are the important goals for this phase of your work?',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText:
      'What is your role in this phase? What responsibilities do you have in your role?',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: 'Submit Your Ideas!',
  },
  { key: 'Notebook_ResultsPage_TabLabel', defaultText: 'Results' },
  { key: 'Notebook_ResultsPage_TitleLeft', defaultText: 'Log!' },
  { key: 'Notebook_ResultsPage_ResultPrompt1Title', defaultText: 'Describe' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText:
      'How would your design look? In what ways would your design idea achieve the main goals? What inspired your idea? Take pictures of any drawings you made for your idea.',
  },
  { key: 'Notebook_ResultsPage_ResultPrompt2Title', defaultText: 'Consider' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      'What might be some strengths of your design idea? What might be some weaknesses?',
  },
  { key: 'Notebook_ResultsPage_ResultPrompt3Title', defaultText: 'CITE' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText:
      'What are the sources you used for your information and ideas?',
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText:
      'Record your answers here. Be sure to restate the questions you are answering to have a complete record of your thoughts.',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: 'Ready to turn in your work?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: 'Log your thoughts',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText:
      "Are you sure you're ready to submit? You cannot make changes once you submit.",
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: 'Submit Your Results!',
  },
  { key: 'Notebook_ReflectPage_TabLabel', defaultText: 'Reflect' },
  { key: 'Notebook_ReflectPage_TitleLeft', defaultText: 'What I learned...' },
  { key: 'Notebook_ReflectPage_TitleRight', defaultText: 'Reflection' },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: 'About myself is....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: 'About working with a team is...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: 'About the Engineering Design Process was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: 'The biggest challenge was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: 'One of our successes was ....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: 'I wish that.........',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: 'Submit Your Ideas!',
  },
];
export const roboticsPlanNotebookTexts: NotebookText[] = [
  { key: 'Notebook_General_ConfirmButton', defaultText: 'Yes' },
  { key: 'Notebook_General_DenyButton', defaultText: 'No' },
  { key: 'Notebook_GuidePage_TabLabel', defaultText: 'Guide' },
  { key: 'Notebook_GuidePage_TitleLeft', defaultText: 'Engineering Design' },
  { key: 'Notebook_GuidePage_TitleRight', defaultText: 'Process' },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: 'Brainstorm',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: 'Plan',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: 'Build',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: 'Test',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      "What - Identify the design challenges in detail at the start of each design process cycle with words and pictures. States the goals for accomplishing the challenge.\n\nWhy - It's important to understand the problem you are trying to solve to know what needs to be fixed or improved, and design the best solution.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      'What - Use this notebook to capture the possible solutions to the challenge with labeled diagrams. Provide citations for ideas that came from outside sources such as online videos or other teams.\n\nWhy - Brainstorming is an important step in the design process because a diversity of ideas will lead to a better solution.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      "What - Explain why the solution was selected through testing and/or a decision matrix. Fully describe the plan to implement the solution.\n\nWhy - It's important to explain the steps of your plan to solve the problem because it helps others understand what you're doing and makes sure everyone agrees on the best way to fix the problem.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      "What - Record the steps to build and program the solution. Include enough detail that the reader can follow the logic used by the team to develop their design, as well as recreate the design from the documentation.\n\nWhy - It's important to explain the steps in detail because it helps others understand how your design works, lets them follow the same process to build it themselves, and makes it easier to fix or improve the design in the future.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      "What - Record all the steps to test the solution, including test results.\n\nWhy - It's important to record the steps to test the solution because it shows whether your solution works as planned, helps you find and fix any problems, and lets others see how well your design performs.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      "What - Show that the design process is repeated multiple times to improve performance on a design goal, or robot/game performance.\n\nWhy - It's important to show that the design process has been repeated multiple times because each time you make improvements, your design gets better and works more like you want it to. This helps you create the best solution possible.",
  },
  { key: 'Notebook_ProblemPage_TabLabel', defaultText: 'Problem' },
  { key: 'Notebook_ProblemPage_TitleLeft', defaultText: 'Information' },
  { key: 'Notebook_ProblemPage_TitleRight', defaultText: 'Team Notes' },
  { key: 'Notebook_ProblemPage_AssignmentName', defaultText: 'Plan' },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: 'Due Date: None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      'In the planning phase, you score your ideas using your own decision matrix. Start by documenting each of the solutions you considered and explain how it scored. You should document at least three finalists.',
  },
  { key: 'Notebook_ProblemPage_CriteriaTitle', defaultText: 'Criteria' },
  { key: 'Notebook_ProblemPage_ConstraintTitle', defaultText: 'Scoring' },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText:
      'Each group member should list and briefly describe one unique criteria that will be considered for each design.',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText:
      'Each group member should list and briefly describe for the same unique criteria how the criteria will be scored.',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: 'Submit Your Ideas!',
  },
  { key: 'Notebook_ResultsPage_TabLabel', defaultText: 'Results' },
  { key: 'Notebook_ResultsPage_TitleLeft', defaultText: 'Log!' },
  { key: 'Notebook_ResultsPage_ResultPrompt1Title', defaultText: 'Strengths' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText:
      'List the criteria for which the design performed well. For each criteria you list, report the scores and your comments.',
  },
  { key: 'Notebook_ResultsPage_ResultPrompt2Title', defaultText: 'Weaknesses' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      'List the criteria for which the design performed poorly. For each criteria you list, report the scores and your comments.',
  },
  { key: 'Notebook_ResultsPage_ResultPrompt3Title', defaultText: 'Conclusion' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText:
      'What is your overall conclusion for this design? Be sure you include take a picture and clearly label the name of the design in your picture!',
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText:
      'Record your answers here. Be sure to restate the questions you are answering to have a complete record of your thoughts.',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: 'Ready to turn in your work?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: 'Log your thoughts',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText:
      "Are you sure you're ready to submit? You cannot make changes once you submit.",
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: 'Submit Your Results!',
  },
  { key: 'Notebook_ReflectPage_TabLabel', defaultText: 'Reflect' },
  { key: 'Notebook_ReflectPage_TitleLeft', defaultText: 'What I learned...' },
  { key: 'Notebook_ReflectPage_TitleRight', defaultText: 'Reflection' },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: 'About myself is....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: 'About working with a team is...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: 'About the Engineering Design Process was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: 'The biggest challenge was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: 'One of our successes was ....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: 'I wish that.........',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: 'Submit Your Ideas!',
  },
];

export const roboticsBuildTestImproveNotebookTexts: NotebookText[] = [
  { key: 'Notebook_General_ConfirmButton', defaultText: 'Yes' },
  { key: 'Notebook_General_DenyButton', defaultText: 'No' },
  { key: 'Notebook_GuidePage_TabLabel', defaultText: 'Guide' },
  { key: 'Notebook_GuidePage_TitleLeft', defaultText: 'Engineering Design' },
  { key: 'Notebook_GuidePage_TitleRight', defaultText: 'Process' },
  {
    key: 'Notebook_GuidePage_Flowchart1Title',
    defaultText: 'Problem',
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Title',
    defaultText: 'Brainstorm',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Title',
    defaultText: 'Plan',
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Title',
    defaultText: 'Build',
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Title',
    defaultText: 'Test',
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Title',
    defaultText: 'Improve',
  },
  {
    key: 'Notebook_GuidePage_Flowchart1Description',
    defaultText:
      "What - Identify the design challenges in detail at the start of each design process cycle with words and pictures. States the goals for accomplishing the challenge.\n\nWhy - It's important to understand the problem you are trying to solve to know what needs to be fixed or improved, and design the best solution.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart2Description',
    defaultText:
      'What - Use this notebook to capture the possible solutions to the challenge with labeled diagrams. Provide citations for ideas that came from outside sources such as online videos or other teams.\n\nWhy - Brainstorming is an important step in the design process because a diversity of ideas will lead to a better solution.',
  },
  {
    key: 'Notebook_GuidePage_Flowchart3Description',
    defaultText:
      "What - Explain why the solution was selected through testing and/or a decision matrix. Fully describe the plan to implement the solution.\n\nWhy - It's important to explain the steps of your plan to solve the problem because it helps others understand what you're doing and makes sure everyone agrees on the best way to fix the problem.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart4Description',
    defaultText:
      "What - Record the steps to build and program the solution. Include enough detail that the reader can follow the logic used by the team to develop their design, as well as recreate the design from the documentation.\n\nWhy - It's important to explain the steps in detail because it helps others understand how your design works, lets them follow the same process to build it themselves, and makes it easier to fix or improve the design in the future.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart5Description',
    defaultText:
      "What - Record all the steps to test the solution, including test results.\n\nWhy - It's important to record the steps to test the solution because it shows whether your solution works as planned, helps you find and fix any problems, and lets others see how well your design performs.",
  },
  {
    key: 'Notebook_GuidePage_Flowchart6Description',
    defaultText:
      "What - Show that the design process is repeated multiple times to improve performance on a design goal, or robot/game performance.\n\nWhy - It's important to show that the design process has been repeated multiple times because each time you make improvements, your design gets better and works more like you want it to. This helps you create the best solution possible.",
  },
  { key: 'Notebook_ProblemPage_TabLabel', defaultText: 'Problem' },
  { key: 'Notebook_ProblemPage_TitleLeft', defaultText: 'Information' },
  { key: 'Notebook_ProblemPage_TitleRight', defaultText: 'Team Notes' },
  {
    key: 'Notebook_ProblemPage_AssignmentName',
    defaultText: 'Build Test and Improve',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDueDate',
    defaultText: 'None!',
  },
  {
    key: 'Notebook_ProblemPage_AssignmentDescription',
    defaultText:
      'Building, testing and improving is a cycle that is repeated many times during the design process. Document each change you make, take pictures, test and record how your prototype performs!',
  },
  { key: 'Notebook_ProblemPage_CriteriaTitle', defaultText: 'Goals' },
  { key: 'Notebook_ProblemPage_ConstraintTitle', defaultText: 'Roles' },
  {
    key: 'Notebook_ProblemPage_CriteriaPlaceholder',
    defaultText: 'What are the important goals for this phase of your work?',
  },
  {
    key: 'Notebook_ProblemPage_ConstraintPlaceholder',
    defaultText:
      'What is your role in this phase? What responsibilities do you have in your role?',
  },
  {
    key: 'Notebook_ProblemPage_SubmitButton',
    defaultText: 'Submit Your Ideas!',
  },
  { key: 'Notebook_ResultsPage_TabLabel', defaultText: 'Results' },
  { key: 'Notebook_ResultsPage_TitleLeft', defaultText: 'Log!' },
  { key: 'Notebook_ResultsPage_ResultPrompt1Title', defaultText: 'Observe' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt1Description',
    defaultText:
      'What were the results of this design test? How are your results different or similar to what you predicted? Be sure to take a picture(s) of your prototype. Consider assigning team members to take images from different viewpoints.',
  },
  { key: 'Notebook_ResultsPage_ResultPrompt2Title', defaultText: 'Explain' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      "How can you explain to others what you discovered about why the design didn't work as you predicted?",
  },
  {
    key: 'Notebook_ResultsPage_ResultPrompt2Description',
    defaultText:
      "How can you explain to others what you discovered about why the design didn't work as you predicted?",
  },
  { key: 'Notebook_ResultsPage_ResultPrompt3Title', defaultText: 'Improve' },
  {
    key: 'Notebook_ResultsPage_ResultPrompt3Description',
    defaultText:
      "How do you think you could fix your design for a better result? Give specific improvements you'd like to try.",
  },
  {
    key: 'Notebook_ResultsPage_ResultPlaceholder',
    defaultText:
      'Record your answers here. Be sure to restate the questions you are answering to have a complete record of your thoughts.',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission1',
    defaultText: 'Ready to turn in your work?',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission2',
    defaultText: 'Log your thoughts',
  },
  {
    key: 'Notebook_ResultsPage_ResultSubmission3',
    defaultText:
      "Are you sure you're ready to submit? You cannot make changes once you submit.",
  },
  {
    key: 'Notebook_ResultsPage_EnergySubmission',
    defaultText: 'Submit Your Results!',
  },
  { key: 'Notebook_ReflectPage_TabLabel', defaultText: 'Reflect' },
  { key: 'Notebook_ReflectPage_TitleLeft', defaultText: 'What I learned...' },
  { key: 'Notebook_ReflectPage_TitleRight', defaultText: 'Reflection' },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt1',
    defaultText: 'About myself is....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt2',
    defaultText: 'About working with a team is...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt3',
    defaultText: 'About the Engineering Design Process was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt4',
    defaultText: 'The biggest challenge was...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt5',
    defaultText: 'One of our successes was ....',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPrompt6',
    defaultText: 'I wish that.........',
  },
  {
    key: 'Notebook_ReflectPage_ReflectPlaceholder',
    defaultText: 'Write your ideas out here...',
  },
  {
    key: 'Notebook_ReflectPage_ReflectSubmit',
    defaultText: 'Submit Your Ideas!',
  },
];

export const notebookTemplateModalLabelMapping = {
  Notebook_General_ConfirmButton: 'Confirm Button',
  Notebook_General_DenyButton: 'Deny Button',
  Notebook_GuidePage_TabLabel: 'Tab Label',
  Notebook_GuidePage_TitleLeft: 'Left Page Title',
  Notebook_GuidePage_TitleRight: 'Right Page Title',
  Notebook_GuidePage_Flowchart1Title: 'Flowchart title 1',
  Notebook_GuidePage_Flowchart1Description: ' Flowchart description 1',
  Notebook_GuidePage_Flowchart2Title: 'Flowchart title 2',
  Notebook_GuidePage_Flowchart2Description: 'Flowchart description 2',
  Notebook_GuidePage_Flowchart3Title: 'Flowchart title 3',
  Notebook_GuidePage_Flowchart3Description: 'Flowchart description 3',
  Notebook_GuidePage_Flowchart4Title: 'Flowchart title 4',
  Notebook_GuidePage_Flowchart4Description: 'Flowchart description 4',
  Notebook_GuidePage_Flowchart5Title: 'Flowchart title 5',
  Notebook_GuidePage_Flowchart5Description: 'Flowchart description 5',
  Notebook_GuidePage_Flowchart6Title: 'Flowchart title 6',
  Notebook_GuidePage_Flowchart6Description: 'Flowchart description 6',
  Notebook_ProblemPage_TabLabel: 'Tab Label',
  Notebook_ProblemPage_TitleLeft: 'Left Page Title',
  Notebook_ProblemPage_TitleRight: 'Right Page Title',
  Notebook_ProblemPage_CriteriaTitle: 'Criteria Title',
  Notebook_ProblemPage_CriteriaPlaceholder: 'Criteria',
  Notebook_ProblemPage_ConstraintTitle: 'Constraints Title',
  Notebook_ProblemPage_ConstraintPlaceholder: 'Constraints',
  Notebook_ProblemPage_SubmitButton: 'Submit Button',
  Notebook_ResultsPage_TabLabel: 'Tab Label',
  Notebook_ResultsPage_TitleLeft: 'Left Page Title',
  Notebook_ResultsPage_ResultPrompt1Title: 'Prompt 1 Title',
  Notebook_ResultsPage_ResultPrompt1Description: 'Prompt 1 Description',
  Notebook_ResultsPage_ResultPrompt2Title: 'Prompt 1',
  Notebook_ResultsPage_ResultPrompt2Description: 'Prompt 2 Description',
  Notebook_ResultsPage_ResultPrompt3Title: 'Prompt 3',
  Notebook_ResultsPage_ResultPrompt3Description: 'Prompt 3 Description',
  Notebook_ResultsPage_ResultPlaceholder: 'Results Placeholder',
  Notebook_ResultsPage_ResultSubmission1: 'Results Submission Step 1',
  Notebook_ResultsPage_ResultSubmission2: 'Results Submission Step 2',
  Notebook_ResultsPage_ResultSubmission3: 'Results Submission Step 3',
  Notebook_ResultsPage_EnergySubmission: 'Energy Submission',
  Notebook_ReflectPage_TabLabel: 'Tab label',
  Notebook_ReflectPage_TitleLeft: 'Left Page Title',
  Notebook_ReflectPage_TitleRight: 'Right Page Title',
  Notebook_ReflectPage_ReflectPrompt1: 'Reflection Prompt 1',
  Notebook_ReflectPage_ReflectPrompt2: 'Reflection Prompt 2',
  Notebook_ReflectPage_ReflectPrompt3: 'Reflection Prompt 3',
  Notebook_ReflectPage_ReflectPrompt4: 'Reflection Prompt 4',
  Notebook_ReflectPage_ReflectPrompt5: 'Reflection Prompt 5',
  Notebook_ReflectPage_ReflectPrompt6: 'Reflection Prompt 6',
  Notebook_ReflectPage_ReflectPlaceholder: 'Reflection Placeholder',
  Notebook_ReflectPage_ReflectSubmit: 'Reflection Submit Button',
};
export const z_enumFromArray = (array: string[]) => {
  return z.enum([array[0], ...array.slice(1)]);
};

export const enumOfTextLabels = [
  'Notebook_ProblemPage_CriteriaTitle',
  'Notebook_ProblemPage_ConstraintTitle',
  'Notebook_ResultsPage_ResultPrompt1Title',
  'Notebook_ResultsPage_ResultPrompt2Title',
  'Notebook_ResultsPage_ResultPrompt3Title',
  'Notebook_ReflectPage_TitleRight',
];

export const mapFieldToTextLabelKey = (
  field: EngineeringNotebookStepField
): string => {
  const mapping: Record<EngineeringNotebookStepField, string> = {
    OBJECTIVES: 'Notebook_ProblemPage_CriteriaTitle',
    CONSTRAINTS: 'Notebook_ProblemPage_ConstraintTitle',
    OBSERVE: 'Notebook_ResultsPage_ResultPrompt1Title',
    EXPLAIN: 'Notebook_ResultsPage_ResultPrompt1Title',
    MODIFY: 'Notebook_ResultsPage_ResultPrompt1Title',
    CONNECTION: 'Notebook_ReflectPage_TitleRight',
    DISCOVERED: 'Notebook_ReflectPage_TitleRight',
    QUESTION: 'Notebook_ReflectPage_TitleRight',
    CONFUSED: 'Notebook_ReflectPage_TitleRight',
    IDEA: 'Notebook_ReflectPage_TitleRight',
    FEELING: 'Notebook_ReflectPage_TitleRight',
  };

  return mapping[field] || '';
};
// export const mapFieldToTextLabelKey = (field: EngineeringNotebookStepField) => {
//   switch (field) {
//     case 'OBJECTIVES':
//       return 'Notebook_ProblemPage_CriteriaTitle';
//     case 'CONSTRAINTS':
//       return 'Notebook_ProblemPage_ConstraintTitle';
//     case 'OBSERVE':
//     case 'EXPLAIN':
//     case 'MODIFY':
//       return 'Notebook_ResultsPage_ResultPrompt1Title';
//     case 'CONNECTION':
//     case 'DISCOVERED':
//     case 'QUESTION':
//     case 'CONFUSED':
//     case 'IDEA':
//     case 'FEELING':
//       return 'Notebook_ReflectPage_TitleRight';
//   }
// };
export type EnumOfTextLabels = (typeof enumOfTextLabels)[number];

export const getExploreNotebookText = ({
  key,
}: {
  key: string;
  locale: 'en' | 'ko';
}) => {
  const text = defaultExploreNotebookTexts.find((text) => text.key === key);
  return text?.defaultText || '';
};

// Define the type for the grouped texts by tab
interface GroupedTexts {
  [tabName: string]: TextLabel[];
}

// Group the default texts by tabs based on key prefixes
export const groupTextsByTab = (texts: TextLabel[]): GroupedTexts => {
  const tabs: GroupedTexts = {};

  texts.forEach((text) => {
    const tabName = text.key.split('_')[1];
    if (!tabs[tabName]) {
      tabs[tabName] = [];
    }
    tabs[tabName].push(text);
  });

  return tabs;
};

export const isMultiline = ({ text }: { text: string }) => {
  return text.includes('\n') || text.length > 50; // Adjust the length threshold as needed
};
