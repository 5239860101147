export const calculateProgress = (completed: number, total: number) => {
  return ((completed ?? 0) / (total ?? 0)) * 100;
};
export const InProgressIcon = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => {
  const heightValue = height.split('-')[1];
  const halfHeight = parseInt(heightValue) / 2;
  return (
    <div
      className={`mr-1 ${width} ${height} rounded-full overflow-hidden relative border border-yellow-500`}
    >
      <div
        className={`${width} h-${halfHeight} absolute left-0 top-0 bg-white`}
      />
      <div
        className={`${width} h-${halfHeight} absolute left-0  bottom-0 bg-yellow-500`}
      />
    </div>
  );
};

export const NotStartedIcon = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => {
  return (
    <div
      className={`mr-1 ${width} ${height} rounded-full overflow-hidden relative border border-black `}
    >
      <div className={`${width} ${height} absolute bg-white`} />
    </div>
  );
};
