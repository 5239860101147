import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import Button from '@/components/Button';
import EmptyScreen from '@/components/EmptyScreen';
import { generateUniversalLink } from '@/utility/index';
import { EngineeringNotebookStepField, FeelingType } from '@prisma/client';
import dayjs from 'dayjs';
import Image from 'next/image';
import React, { createContext, useContext } from 'react';

import {
  CreateSpaceReflectionReport,
  EngineeringNotebookStepsWithLike,
} from 'types/models/Assignment';
import {
  FeelingIcon,
  KudosIcon,
  setNotebookStepIcon,
} from '../Assignments/Log/LogItems';
import { useLikesAndMessagesReportManagerContext } from './useLikesAndMessagesReportManager';

type Student = {
  firstName: string;
  lastName: string;
  id: string;
  [key: string]: any;
};
interface CreateSpaceReportManagerContextProps {
  renderObjectives: (
    objective: EngineeringNotebookStepsWithLike
  ) => JSX.Element;
  renderConstraints: (
    constraint: EngineeringNotebookStepsWithLike
  ) => JSX.Element;
  RenderTestingResults: (
    title: string,
    testingResults:
      | Array<{
          entries: (EngineeringNotebookStepsWithLike & {
            signedImageUrl?: string;
          })[];
          id: string;
          firstName: string;
          lastName: string;
        }>[]
      | undefined
  ) => JSX.Element;

  renderKudosAndReflection: (
    entry: CreateSpaceReflectionReport,
    key: string
  ) => JSX.Element;

  Entries: ({
    title,
    students,
    renderEntry,
    entryKey,
    type,
  }: {
    title: string;
    students: Student[];
    renderEntry: (
      entry: EngineeringNotebookStepsWithLike & { signedImageUrl?: string }
    ) => JSX.Element;
    entryKey: string;
    type: EngineeringNotebookStepField;
  }) => JSX.Element;
  ReplaySection: ({
    assignmentId,
    viewReplayInAppText,
    stepByStepText,
  }: {
    assignmentId: string;
    stepByStepText: string;
    viewReplayInAppText: string;
  }) => JSX.Element;

  ReflectionsSection: ({
    kudosAndReflections,
    renderKudosAndReflection,
    reflectionDisplayText,
  }: {
    kudosAndReflections: CreateSpaceReflectionReport[];

    renderKudosAndReflection: (
      entry: CreateSpaceReflectionReport,
      key: string
    ) => JSX.Element;
    reflectionDisplayText: string;
  }) => JSX.Element;
}

export const CreateSpaceReportManagerContext =
  createContext<CreateSpaceReportManagerContextProps | null>(null);
export const useCreateSpaceReportManagerContext = () => {
  const context = useContext(CreateSpaceReportManagerContext);

  if (!context) {
    throw new Error(
      'useReportManagerContext must be used within the ReportManagerContext Provider'
    );
  }
  return context;
};

export const CreateSpaceReportManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { LikeIcon, MessageIcon, ReflectionType } =
    useLikesAndMessagesReportManagerContext();

  const renderObjectives = (criteria: EngineeringNotebookStepsWithLike) => {
    return (
      <div
        className="flex flex-row justify-between items-center py-3"
        key={criteria.id}
      >
        <div className="flex justify-start gap-2">
          <span className="italic">Draft</span>
          <p className="flex-grow text-ellipsis pl-3  ">{criteria.data} </p>
        </div>
        <div className="flex justify-between items-center gap-1">
          <MessageIcon entryType="engineeringNotebookStep" entry={criteria} />
          <LikeIcon
            entry={criteria}
            entryType="engineeringNotebookStep"
            assignmentType="CREATE_SPACE"
          />
          <Timestamp createdAt={criteria.createdAt} />
        </div>
      </div>
    );
  };
  const renderConstraints = (constraint: EngineeringNotebookStepsWithLike) => (
    <div
      className="flex flex-row justify-between items-center py-3"
      key={constraint.id}
    >
      <div className="flex justify-start gap-2">
        <span className="italic">Draft</span>
        <p className="  text-ellipsis pl-3">{constraint.data}</p>
      </div>
      <div className="flex justify-between items-center gap-1">
        <MessageIcon entryType="engineeringNotebookStep" entry={constraint} />
        <LikeIcon
          entry={constraint}
          entryType="engineeringNotebookStep"
          assignmentType="CREATE_SPACE"
        />
        <Timestamp createdAt={constraint.createdAt} />
      </div>
    </div>
  );

  const RenderTestingResults = (
    title: string,
    testingResults:
      | Array<{
          entries: (EngineeringNotebookStepsWithLike & {
            signedImageUrl?: string;
          })[];
          id: string;
          firstName: string;
          lastName: string;
        }>[]
      | undefined
  ) => {
    return (
      <div className="pb-6 ">
        <div className="flex items-end pb-4">
          <h1>{title}</h1>
          <hr className="flex-grow  ml-2" />
        </div>
        <div className="flex flex-col w-full">
          {testingResults && testingResults.length > 0 ? (
            testingResults.map((testingResultEntries, index) => (
              <Accordion
                type="single"
                collapsible
                className="w-full  text-gray-500"
                key={index}
              >
                <AccordionItem value={index.toString() + 1} showBorder={true}>
                  <AccordionTrigger className="flex items-center p-1 ">
                    <span className="font-bold py-2">Test {index + 1}</span>
                  </AccordionTrigger>
                  <AccordionContent className="pb-0">
                    {testingResultEntries?.map(
                      (
                        {
                          entries,
                          firstName,
                          id,
                          lastName,
                        }: {
                          entries: (EngineeringNotebookStepsWithLike & {
                            signedImageUrl?: string;
                          })[];
                          firstName: string;
                          id: string;
                          lastName: string;
                        },
                        studentIndex
                      ) => (
                        <Accordion
                          type="single"
                          collapsible
                          className="w-full px-4"
                          key={studentIndex}
                        >
                          <AccordionItem
                            value={studentIndex.toString() + 1}
                            showBorder={true}
                            key={studentIndex}
                          >
                            <AccordionTrigger className={`flex items-center `}>
                              <span className="font-bold">{firstName}</span>
                            </AccordionTrigger>
                            <AccordionContent>
                              {entries && entries.length > 0 ? (
                                entries.map((entry, entryIndex) => (
                                  <div
                                    key={entryIndex}
                                    className={`flex flex-row justify-between items-center p-3 ${
                                      entryIndex < entries.length - 1
                                        ? 'border-b'
                                        : ''
                                    }`}
                                  >
                                    <div className="flex justify-start gap-3">
                                      <span className="flex gap-3 min-w-24 text-sm max-h-12">
                                        {setNotebookStepIcon()}
                                        <p>
                                          {entry.signedImageUrl
                                            ? 'SCREEN SHOT'
                                            : entry?.field === 'MODIFY'
                                              ? 'IMPROVE'
                                              : entry?.field}
                                        </p>
                                      </span>
                                      <p className="flex-grow text-ellipsis pl-4">
                                        {entry.signedImageUrl ? (
                                          <Image
                                            alt="Picture of the Student"
                                            width={300}
                                            height={200}
                                            src={entry.signedImageUrl}
                                          />
                                        ) : (
                                          entry.data
                                        )}
                                      </p>
                                    </div>
                                    <div className="flex justify-end items-center space-x-1 ">
                                      <MessageIcon
                                        entryType={
                                          entry.signedImageUrl
                                            ? 'image'
                                            : 'engineeringNotebookStep'
                                        }
                                        entry={entry}
                                        userInfo={{ firstName, lastName, id }}
                                      />

                                      <LikeIcon
                                        entry={entry}
                                        entryType={
                                          entry.signedImageUrl
                                            ? 'image'
                                            : 'engineeringNotebookStep'
                                        }
                                        assignmentType="CREATE_SPACE"
                                      />
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="flex pl-4 py-1 text-gray-500">
                                  <span>
                                    {firstName} has no entries in this
                                    iteration.
                                  </span>
                                </div>
                              )}
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      )
                    )}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))
          ) : (
            <div className="flex items-center justify-center py-4 text-gray-500">
              <span>No students have entries in this field.</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Helper component to display timestamp
  const Timestamp = ({ createdAt }: { createdAt: Date }) => (
    <div className="flex justify-end truncate w-28">
      <span>{dayjs(createdAt).format('MM/DD hh:mm a')}</span>
    </div>
  );

  const renderKudosAndReflection = (
    entry: CreateSpaceReflectionReport,
    key: string
  ) => {
    return (
      <div
        className="flex flex-row justify-between items-center py-3 border-b text-gray-500"
        key={key}
      >
        <div className="flex justify-start gap-3">
          <div className="flex  items-center">
            <span className="flex gap-4 min-w-24 font-bold max-h-12  ">
              <span className="italic">
                {' '}
                {entry?.sender
                  ? KudosIcon(entry.isPositive as any)
                  : entry.data
                    ? setNotebookStepIcon(
                        entry.field as EngineeringNotebookStepField
                      )
                    : FeelingIcon(entry.type as any)}
              </span>
              <div className="truncate max-w-20">
                {entry?.sender
                  ? entry.sender.firstName
                  : entry?.student?.firstName}
              </div>
            </span>
          </div>

          <div className="flex-grow text-ellipsis pl-3">
            {entry.data ? (
              <div className="flex items-center align-middle">
                <div className="text-sm text-gray-500 font-bold line-clamp-2">
                  {entry?.data}
                </div>
              </div>
            ) : (
              <ReflectionType
                type={entry.type as FeelingType}
                sender={entry?.sender as Student}
                receiver={entry?.receiver as Student}
                isPositive={entry.isPositive as boolean}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between items-center space-x-1">
          <MessageIcon
            entryType={
              entry?.sender
                ? 'kudo'
                : entry.page
                  ? 'engineeringNotebookStep'
                  : 'feelingsTracker'
            }
            entry={entry}
            userInfo={entry.student ?? entry.sender}
          />

          <LikeIcon
            entry={entry as any}
            entryType={
              entry?.sender
                ? 'kudo'
                : entry.page
                  ? 'engineeringNotebookStep'
                  : 'feelingsTracker'
            }
            assignmentType="CREATE_SPACE"
          />
          <span className="text-sm text-ellipsis whitespace-nowrap">
            <Timestamp createdAt={entry.createdAt} />
          </span>
        </div>
      </div>
    );
  };

  const StudentEntry = ({
    student,
    entries,
    renderEntry,
    type,
  }: {
    student: Student;
    entries: (EngineeringNotebookStepsWithLike & { signedImageUrl: string })[];
    renderEntry: (
      entry: EngineeringNotebookStepsWithLike & { signedImageUrl: string }
    ) => JSX.Element;
    type: EngineeringNotebookStepField;
  }) => {
    const value = `${student.id}`;

    return (
      <Accordion
        type="single"
        key={student.id}
        collapsible
        className="w-full text-gray-500"
      >
        <AccordionItem value={value}>
          <div
            className={`flex flex-row justify-between items-center gap-2 py-1`}
            key={student.id}
          >
            <AccordionTrigger className="max-h-14" />
            <div className="flex gap-4 items-center">
              <span className="flex gap-4 min-w-28 text-sm font-bold max-h-12">
                {setNotebookStepIcon(type)}
                {student.firstName}
              </span>
              <span className="max-h-12 pr-4">Final</span>
            </div>
            <span className="flex-grow text-ellipsis py-1">
              {entries && entries.length > 0
                ? entries[0].data
                : 'This student has no entries'}
            </span>
            <div className="flex justify-between">
              {entries && entries.length > 0 && (
                <>
                  <MessageIcon
                    entryType="engineeringNotebookStep"
                    entry={entries[0]}
                    userInfo={student}
                  />

                  <LikeIcon
                    entry={entries[0]}
                    entryType="engineeringNotebookStep"
                    assignmentType="CREATE_SPACE"
                  />
                </>
              )}
            </div>

            <span className="text-sm text-ellipsis whitespace-nowrap">
              {' '}
              {entries && entries.length > 0 ? (
                <Timestamp createdAt={entries[0].createdAt} />
              ) : (
                ''
              )}
            </span>
          </div>
          <AccordionContent>
            {entries && entries.length > 1 ? (
              <div className=" pl-40 text-sm flex flex-col gap-4">
                {entries.slice(1).map(renderEntry)}
              </div>
            ) : (
              <div className="flex items-center justify-center py-1 text-gray-500">
                <span>No draft entries available.</span>
              </div>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  };

  const Entries = ({
    title,
    students,
    renderEntry,
    entryKey,
    type,
  }: {
    title: string;
    students: Student[];
    renderEntry: (
      entry: EngineeringNotebookStepsWithLike & { signedImageUrl?: string }
    ) => JSX.Element;
    entryKey: string;
    type: EngineeringNotebookStepField;
  }) => (
    <div className="pb-6">
      <div className="flex items-end">
        <h1>{title}</h1>
        <hr className="flex-grow border-black border-b-1 ml-2" />
      </div>
      <div className="flex flex-col w-full">
        {students.length > 0 ? (
          students.map((student) => (
            <StudentEntry
              key={student.id}
              student={student}
              entries={student[entryKey]}
              renderEntry={renderEntry}
              type={type}
            />
          ))
        ) : (
          <div
            className="flex items-center justify-center py-4 text-gray-500"
            key={entryKey}
          >
            <span>No students have entries in this field.</span>
          </div>
        )}
      </div>
    </div>
  );

  const ReflectionsSection = ({
    kudosAndReflections,

    renderKudosAndReflection,
    reflectionDisplayText,
  }: {
    kudosAndReflections: CreateSpaceReflectionReport[];

    renderKudosAndReflection: (
      entry: CreateSpaceReflectionReport,

      key: string
    ) => JSX.Element;
    reflectionDisplayText: string;
  }) => {
    return (
      <div className="py-4">
        <div className="flex items-end truncate">
          <h1>{reflectionDisplayText}</h1>
          <hr className="flex-grow border-black border-b-1 ml-2" />
        </div>
        <div
          className={`flex flex-col w-full pt-2 pb-4 max-h-128 overflow-auto   
          `}
        >
          {kudosAndReflections && kudosAndReflections.length > 0 ? (
            kudosAndReflections.map((reflectionAndKudo) =>
              renderKudosAndReflection(reflectionAndKudo, reflectionAndKudo.id)
            )
          ) : (
            <EmptyScreen
              headline="No Reflections"
              description="No reflections have been submitted for this assignment."
            />
          )}
        </div>
      </div>
    );
  };

  const ReplaySection = ({
    assignmentId,
    viewReplayInAppText,
    stepByStepText,
  }: {
    assignmentId: string;
    stepByStepText: string;
    viewReplayInAppText: string;
  }) => (
    <div className="py-4">
      <div className="flex items-end">
        <h1>{stepByStepText}</h1>
        <hr className="flex-grow border-black border-b-1 ml-2" />
      </div>
      <div className="flex flex-col gap-2 pb-4">
        <p className="py-1">
          {' '}
          You will be directed back to the app to view the step by step replay.
        </p>
        <Button
          color="outline"
          size={'lg'}
          className=" max-w-52"
          href={`${generateUniversalLink({
            assignmentId: assignmentId,
            replay: true,
          })}`}
        >
          {viewReplayInAppText}
        </Button>
      </div>
    </div>
  );

  return (
    <CreateSpaceReportManagerContext.Provider
      value={{
        renderObjectives,
        renderConstraints,
        RenderTestingResults,

        renderKudosAndReflection,
        ReplaySection,
        Entries,
        ReflectionsSection,
      }}
    >
      {children}
    </CreateSpaceReportManagerContext.Provider>
  );
};
