import { Tasks } from '@prisma/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';

import { TaskUpdateFormData } from 'types/models/Assignment';
import QueryKeys from '../queryKeys';

const updateTask = async ({
  taskId,
  taskData,
}: {
  taskId: string;
  taskData: TaskUpdateFormData;
}) => {
  const { data } = await axiosInstance.put<Tasks>(
    `${Routes.API.TASKS}/${taskId}`,
    taskData
  );
  return data;
};

export const useUpdateTask = (taskId: string, userId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Tasks, AxiosError, TaskUpdateFormData, unknown>({
    mutationFn: (data) => updateTask({ taskId, taskData: data }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS_HOME, userId],
      });
    },
  });

  return mutation;
};
