import { useMutation } from '@tanstack/react-query';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';
import { ClientUser, UserUpdateHasSeenOnboarding } from 'types/models/User';

const hasSeenOnBoarding = async ({
  hasSeenOnBoarding,
}: UserUpdateHasSeenOnboarding) => {
  const { data } = await axiosInstance.patch<ClientUser>(
    `${Routes.API.USERS}/onboarding`,
    { hasSeenOnBoarding }
  );
  return data;
};

export const useHasSeenOnboarding = () => {
  const mutation = useMutation<
    ClientUser,
    Error,
    UserUpdateHasSeenOnboarding,
    unknown
  >({
    mutationFn: (data: UserUpdateHasSeenOnboarding) => hasSeenOnBoarding(data),
  });
  return mutation;
};
