// contexts/WebViewContext.tsx
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface WebViewContextType {
  isWebView: boolean;
  setIsWebView: (isWebView: boolean) => void;
}

const WebViewContext = createContext<WebViewContextType | undefined>(undefined);

export const useWebView = (): WebViewContextType => {
  const context = useContext(WebViewContext);
  if (!context) {
    throw new Error('useWebView must be used within a WebViewProvider');
  }
  return context;
};

interface WebViewProviderProps {
  children: ReactNode;
}

export const WebViewProvider: React.FC<WebViewProviderProps> = ({
  children,
}) => {
  const [isWebView, setIsWebView] = useState<boolean>(
    () =>
      typeof window !== 'undefined' &&
      window.sessionStorage.getItem('isWebView') === 'true'
  );

  useEffect(() => {
    if (isWebView) {
      sessionStorage.setItem('isWebView', 'true');
    } else {
      sessionStorage.removeItem('isWebView');
    }
  }, [isWebView]);

  return (
    <WebViewContext.Provider value={{ isWebView, setIsWebView }}>
      {children}
    </WebViewContext.Provider>
  );
};
