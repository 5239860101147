import { Like } from '@prisma/client';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';

const deleteLike = async ({ likeId }: { likeId: string }) => {
  const { data } = await axiosInstance.delete<Like[]>(
    `${Routes.API.LIKES}/${likeId}`
  );
  return data;
};

export const useDeleteLike = () => {
  const mutation = useMutation<
    Like[],
    AxiosError,
    {
      likeId: string;
    },
    unknown
  >({
    mutationFn: (data: { likeId: string }) => deleteLike(data),
    throwOnError: false,
  });

  return mutation;
};
