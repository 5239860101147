import {
  groupTextsByTab,
  notebookTemplateModalLabelMapping,
} from '@/utility/notebookTemplates';
import { TextLabel, TextLabelSet } from '@prisma/client';
import { useSession } from 'next-auth/react';
import React, { useEffect, useMemo } from 'react';
import { FieldErrors, UseFormReturn, useForm } from 'react-hook-form';
import { TextLabelSetWithTextLabels } from 'types/models/NotebookTemplate';
import QueryKeys from '../react-query/queryKeys';
import { useTextLabels } from '../react-query/useTextLabels/useTextLabels';

interface NotebookTemplateSetContextProps {
  currentNotebookTemplateSet: TextLabelSetWithTextLabels | null;
  isNotebookTemplateSetModalOpen: boolean;
  openNotebookTemplateSetModal: ({
    textLabelSet,
  }: {
    textLabelSet: TextLabelSet;
  }) => void;
  closeNotebookTemplateSetModal: () => void;
  textLabelsSetData: TextLabelSetWithTextLabels[] | undefined;
  errors: FieldErrors;
  methods: UseFormReturn;
  isLoading: boolean;
  error: unknown;
  isError: boolean;
  groupedTextLabels: GroupedTexts;
}

// Extend your TextLabel type to include the user-friendly name
interface ExtendedTextLabel extends TextLabel {
  userFriendlyName: string;
}

interface GroupedTexts {
  [groupName: string]: ExtendedTextLabel[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const NotebookTemplateSetModalContext =
  React.createContext<NotebookTemplateSetContextProps>({
    currentNotebookTemplateSet: null,
    groupedTextLabels: {},
    isNotebookTemplateSetModalOpen: false,
    openNotebookTemplateSetModal: noop,
    closeNotebookTemplateSetModal: noop,
    errors: {},
    methods: {} as UseFormReturn,
    isLoading: false,
    error: null,
    isError: false,
    textLabelsSetData: [],
  });
export const NotebookTemplateSetProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentNotebookTemplateSet, setCurrentNotebookTemplateSet] =
    React.useState<TextLabelSetWithTextLabels | null>(null);

  const [isNotebookTemplateSetModalOpen, setIsNotebookTemplateSetModalOpen] =
    React.useState(false);

  const openNotebookTemplateSetModal = ({
    textLabelSet,
  }: {
    textLabelSet: TextLabelSet;
  }) => {
    setCurrentNotebookTemplateSet(textLabelSet);
    setIsNotebookTemplateSetModalOpen(true);
  };

  const closeNotebookTemplateSetModal = () => {
    setCurrentNotebookTemplateSet(null);
    setIsNotebookTemplateSetModalOpen(false);
  };

  const { data, status } = useSession();
  const role = data?.user?.role;
  const teacherId =
    role === 'TEACHER' || role === 'ADMIN' ? data?.user?.id : '';

  const methods = useForm({
    // resolver: zodResolver(),
  });

  const {
    data: textLabelsSetData,
    isFetching: isFetchingTextLabels,
    isError,
    error,
  } = useTextLabels({
    queryKey: [QueryKeys.TEXT_LABELS_HOME],
    teacherId,
    includeDefaultText: true,
  });

  const isLoading = status === 'loading' || isFetchingTextLabels;

  useEffect(() => {
    if (!currentNotebookTemplateSet) {
      methods.reset();
    }
  }, [currentNotebookTemplateSet]);

  const { errors } = methods.formState;

  const groupedTextLabels = useMemo((): GroupedTexts => {
    // First group texts by tab
    const groups = groupTextsByTab(
      currentNotebookTemplateSet?.textLabels || []
    );
    // Then map each text to include the user-friendly name and sort them
    return Object.keys(groups).reduce((acc, groupName) => {
      acc[groupName] = groups[groupName]
        .filter((text) =>
          Object.hasOwnProperty.call(
            notebookTemplateModalLabelMapping,
            text.key
          )
        ) // Filter out labels not in the mapping
        .map((text) => ({
          ...text,
          userFriendlyName:
            (notebookTemplateModalLabelMapping as { [key: string]: string })[
              text.key
            ] || text.key,
        }))
        .sort((a, b) => {
          const orderA = Object.keys(notebookTemplateModalLabelMapping).indexOf(
            a.key
          );
          const orderB = Object.keys(notebookTemplateModalLabelMapping).indexOf(
            b.key
          );
          return orderA - orderB;
        });
      return acc;
    }, {} as GroupedTexts);
  }, [currentNotebookTemplateSet?.textLabels]);

  return (
    <NotebookTemplateSetModalContext.Provider
      value={{
        errors,
        error,
        isLoading,
        isError,
        groupedTextLabels,
        methods,
        currentNotebookTemplateSet,
        isNotebookTemplateSetModalOpen,
        textLabelsSetData,
        openNotebookTemplateSetModal,
        closeNotebookTemplateSetModal,
      }}
    >
      {children}
    </NotebookTemplateSetModalContext.Provider>
  );
};
