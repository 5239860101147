import { showToast } from '@/components/Alert/toast';
import { zodResolver } from '@hookform/resolvers/zod';
import { taskCreateSchema } from '@lib-server/validation';
import { Tasks, TasksType } from '@prisma/client';
import { useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, {
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FieldErrors, UseFormReturn, useForm } from 'react-hook-form';
import {
  TasksCreateFormData,
  TasksUpdateFormData,
} from 'types/models/Assignment';
import QueryKeys from '../react-query/queryKeys';
import { useCreateTask } from '../react-query/tasks/useCreateTasks';
import { useTasksTextLabels } from '../react-query/tasks/useTasksTextLabels';
import { useUpdateTask } from '../react-query/tasks/useUpdateTask';

interface FilterType {
  grade: string;
  unit: string;
  taskType: string;
}

interface TaskModalContextProps {
  currentTask: Tasks | null;
  isCreateSpaceTaskModalOpen: boolean;
  isChallengeSetTaskModalOpen: boolean;
  filters: FilterType;
  taskType: TasksType;
  setFilters: (filters: FilterType) => void;
  openChallengeSetTaskModal: (task?: Tasks) => void;
  closeChallengeSetTaskModal: () => void;
  openCreateSpaceTaskModal: (task?: Tasks) => void;
  closeCreateSpaceTaskModal: () => void;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  goNext: () => void;
  errors: FieldErrors<TasksCreateFormData>;
  methods: UseFormReturn<TasksCreateFormData>;
  isLoading: boolean;
  error: unknown;
  isError: boolean;
  maxSteps: number;
  navigateToStep: (step: number) => void;
  onSubmitCreateTask: (data: TasksUpdateFormData) => void;
  selectedNodes: string[];
  setSelectedNodes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUnit: string;
  textLabelsOptions: { label: string; value: string }[];
  setSelectedUnit: React.Dispatch<React.SetStateAction<string>>;
}
const defaultFilters = { grade: '', unit: '', taskType: 'CREATE_SPACE' };

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
export const TaskModalContext = React.createContext<TaskModalContextProps>({
  currentTask: null,
  taskType: 'CREATE_SPACE',
  filters: { grade: '', unit: '', taskType: 'CREATE_SPACE' },
  isChallengeSetTaskModalOpen: false,
  isCreateSpaceTaskModalOpen: false,
  setFilters: noop,
  openChallengeSetTaskModal: noop,
  openCreateSpaceTaskModal: noop,
  closeCreateSpaceTaskModal: noop,
  closeChallengeSetTaskModal: noop,
  goNext: noop,
  currentStep: 1,
  setCurrentStep: noop,
  errors: {},
  methods: {} as UseFormReturn<TasksCreateFormData>,
  isLoading: false,
  error: null,
  isError: false,
  maxSteps: 2,
  navigateToStep: noop,
  onSubmitCreateTask: noop,
  textLabelsOptions: [],
  selectedNodes: [],
  setSelectedNodes: noop,
  selectedUnit: '',
  setSelectedUnit: noop,
});
export const TaskModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentTask, setCurrentTask] = React.useState<Tasks | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [taskType, setTaskType] = useState<TasksType>('CREATE_SPACE');
  const [isChallengeSetTaskModalOpen, setIsChallengeSetTaskModalOpen] =
    React.useState(false);
  const [isCreateSpaceTaskModalOpen, setIsCreateSpaceTaskModalOpen] =
    React.useState(false);

  const [filters, _setFilters] = React.useState(defaultFilters);

  const maxSteps = 2;

  const queryClient = useQueryClient();

  const setFilters = useCallback(
    (newFilters: FilterType) => _setFilters({ ...filters, ...newFilters }),
    [filters]
  );

  const openModal = useCallback(
    (setModalOpen: (open: boolean) => void, task?: Tasks) => {
      setCurrentTask(task ?? null);
      setModalOpen(true);
    },
    []
  );

  const closeModal = useCallback((setModalOpen: (open: boolean) => void) => {
    setCurrentTask(null);
    setModalOpen(false);
    setCurrentStep(1);

    setSelectedUnit('');
  }, []);

  const openChallengeSetTaskModal = useCallback(
    (task?: Tasks) => {
      openModal(setIsChallengeSetTaskModalOpen, task);
      setTaskType('CHALLENGE_SET');
    },
    [openModal]
  );
  const closeChallengeSetTaskModal = useCallback(
    () => closeModal(setIsChallengeSetTaskModalOpen),
    [closeModal]
  );

  const openCreateSpaceTaskModal = useCallback(
    (task?: Tasks) => openModal(setIsCreateSpaceTaskModalOpen, task),

    [openModal]
  );
  const closeCreateSpaceTaskModal = useCallback(
    () => closeModal(setIsCreateSpaceTaskModalOpen),
    [closeModal]
  );

  const { data, status } = useSession();
  const teacherId =
    data?.user.role === 'TEACHER' || data?.user.role === 'ADMIN'
      ? (data?.user.id as string)
      : '';

  const methods = useForm<TasksCreateFormData>({
    resolver: zodResolver(taskCreateSchema),
  });

  const {
    mutate: createTask,
    isPending: isLoadingCreatingNewTask,
    isError: isCreatingNewTaskError,
    error: creatingNewTaskError,
  } = useCreateTask();

  const {
    mutate: updateTask,
    isPending: isLoadingUpdatingTask,
    isError: isUpdatingTaskError,
    error: updatingTaskError,
  } = useUpdateTask(currentTask?.id ?? '', teacherId ? teacherId : '');

  const { data: textLabelsData, isPending } = useTasksTextLabels({
    queryKey: [QueryKeys.TEXT_LABELS, teacherId ? teacherId : ''],
    enabled: !!teacherId,
  });
  const textLabelsOptions =
    textLabelsData?.map((label) => ({
      label: label.displayName,
      value: label.id,
    })) ?? [];

  const isError = isCreatingNewTaskError || isUpdatingTaskError;
  const error = creatingNewTaskError ?? updatingTaskError;

  const isLoading =
    status === 'loading' ||
    isLoadingCreatingNewTask ||
    isLoadingUpdatingTask ||
    isPending;

  const onSubmitCreateTask = async ({
    notebookDescription,
    displayName,
    grade,
    unit,
    notebookSummary,
    challenges,
    textLabelSetId,
  }: TasksUpdateFormData | TasksCreateFormData) => {
    if (!teacherId) {
      return;
    }
    if (currentTask) {
      if (currentTask.type === 'CHALLENGE_SET' && !challenges?.length) {
        showToast('Please select at least one challenge', 'error');
        return;
      }
      updateTask(
        {
          notebookDescription,
          notebookSummary,
          displayName,
          unit,
          grade,
          challenges,
          textLabelSetId: textLabelSetId ?? '',
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.TASKS_HOME],
            });
            methods.reset();
            showToast('Task updated successfully', 'success');
            closeChallengeSetTaskModal();
          },
        }
      );
    } else {
      if (taskType === 'CHALLENGE_SET' && !challenges?.length) {
        showToast('Please select at least one challenge', 'error');
        return;
      }
      createTask(
        {
          notebookDescription,
          notebookSummary,
          displayName,
          unit,
          grade,
          createdBy: teacherId,
          type: taskType,
          challenges: challenges ?? [],
          textLabelSetId: textLabelSetId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.TASKS_HOME],
            });
            methods.reset();
            showToast('Task created successfully', 'success');
            closeChallengeSetTaskModal();
          },
        }
      );
    }
  };

  useEffect(() => {
    if (currentTask) {
      methods.setValue('displayName', currentTask.displayName);
      methods.setValue(
        'notebookDescription',
        currentTask.notebookDescription ?? ''
      );
      methods.setValue('grade', currentTask.grade);
      // methods.setValue('textLabelName', currentTask.textLabe);
      methods.setValue('unit', currentTask.unit);
      setSelectedUnit(currentTask.unit);
      methods.setValue('textLabelSetId', currentTask.textLabelSetId ?? '');
      taskType === 'CHALLENGE_SET' &&
        methods.setValue('challenges', currentTask.challenges);
      taskType === 'CREATE_SPACE' &&
        methods.setValue('notebookSummary', currentTask.notebookSummary ?? '');

      taskType === 'CHALLENGE_SET' && setSelectedNodes(currentTask.challenges);
    } else {
      methods.reset();
    }
  }, [currentTask, methods]);

  // Fixed navigation function

  const navigateToStep = useCallback((step: number) => {
    setCurrentStep(step + 1);
  }, []);

  const goNext = useCallback(async () => {
    const isFormValid = await methods.trigger();
    if (isFormValid) {
      startTransition(() => {
        setCurrentStep((prev) => Math.min(prev + 1, 2));
      });
    }
  }, [methods]);

  const { errors } = methods.formState;

  return (
    <TaskModalContext.Provider
      value={{
        textLabelsOptions,
        errors,
        error,
        isLoading,
        isError,
        goNext,
        methods,
        maxSteps,
        currentTask,
        isChallengeSetTaskModalOpen,
        isCreateSpaceTaskModalOpen,
        filters,
        setFilters,
        openChallengeSetTaskModal,
        closeChallengeSetTaskModal,
        openCreateSpaceTaskModal,
        closeCreateSpaceTaskModal,
        currentStep,
        setCurrentStep,
        navigateToStep,
        onSubmitCreateTask,
        selectedNodes,
        setSelectedNodes,
        selectedUnit,
        setSelectedUnit,
        taskType,
      }}
    >
      {children}
    </TaskModalContext.Provider>
  );
};
