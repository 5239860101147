import { classNames } from '@/utility/className';
import toast, { Toast, ToastOptions } from 'react-hot-toast';
import { FiCheck, FiInfo } from 'react-icons/fi';
type IToast = {
  message: string;
  toastVisible: boolean;
  toastId: string;
  onClose: (toastId: string) => void;
};

export const SuccessToast = ({
  message,
  toastVisible,
  onClose,
  toastId,
}: IToast) => (
  <button
    className={classNames(
      'data-testid-toast-success bg-[#111827] text-white mb-2 flex h-auto space-x-2 rounded-md p-3 text-sm font-semibold shadow-md md:max-w-sm',
      toastVisible && 'animate-fade-in-up cursor-pointer'
    )}
    onClick={() => onClose(toastId)}
  >
    <span className="mt-0.5">
      <FiCheck className="h-4 w-4" />
    </span>
    <div data-testid="success-toast" className="text-left ">
      {message}
    </div>
  </button>
);

export const ErrorToast = ({
  message,
  toastVisible,
  onClose,
  toastId,
}: IToast) => (
  <button
    className={classNames(
      'animate-fade-in-up bg-[#f9e3e2] text-error mb-2 flex h-auto space-x-2 rounded-md p-3 text-sm font-semibold shadow-md md:max-w-sm',
      toastVisible && 'animate-fade-in-up cursor-pointer'
    )}
    onClick={() => onClose(toastId)}
  >
    <span className="mt-0.5">
      <FiInfo className="h-4 w-4" />
    </span>
    <div data-testid="error-toast" className="text-left">
      {message}
    </div>
  </button>
);

export const WarningToast = ({
  message,
  toastVisible,
  onClose,
  toastId,
}: IToast) => (
  <button
    className={classNames(
      'animate-fade-in-up bg-[#111827] text-white mb-2 flex h-auto space-x-2 rounded-md p-3 text-sm font-semibold shadow-md md:max-w-sm',
      toastVisible && 'animate-fade-in-up cursor-pointer'
    )}
    onClick={() => onClose(toastId)}
  >
    <span className="mt-0.5">
      <FiInfo className="h-4 w-4" />
    </span>
    <div data-testid="warning-toast" className="text-left text-white">
      {message}
    </div>
  </button>
);

export const DefaultToast = ({
  message,
  toastVisible,
  onClose,
  toastId,
}: IToast) => (
  <button
    className={classNames(
      'animate-fade-in-up bg-[#111827] text-white mb-2 flex h-auto space-x-2 rounded-md p-3 text-sm font-semibold shadow-md md:max-w-sm',
      toastVisible && 'animate-fade-in-up cursor-pointer'
    )}
    onClick={() => onClose(toastId)}
  >
    <span className="mt-0.5">
      <FiCheck className="h-4 w-4" />
    </span>
    <div data-testid="default-toast" className="text-left">
      {message}
    </div>
  </button>
);

const TOAST_VISIBLE_DURATION = 6000;

type ToastVariants = 'success' | 'warning' | 'error';

export function showToast(
  message: string,
  variant: ToastVariants,
  // Options or duration (duration for backwards compatibility reasons)
  options: number | ToastOptions = TOAST_VISIBLE_DURATION
) {
  //
  const _options: ToastOptions =
    typeof options === 'number' ? { duration: options } : options;
  if (!_options.duration) _options.duration = TOAST_VISIBLE_DURATION;

  const onClose = (toastId: string) => {
    toast.remove(toastId);
  };
  const toastElements: { [x in ToastVariants]: (t: Toast) => JSX.Element } = {
    success: (t) => (
      <SuccessToast
        message={message}
        toastVisible={t.visible}
        onClose={onClose}
        toastId={t.id}
      />
    ),
    error: (t) => (
      <ErrorToast
        message={message}
        toastVisible={t.visible}
        onClose={onClose}
        toastId={t.id}
      />
    ),
    warning: (t) => (
      <WarningToast
        message={message}
        toastVisible={t.visible}
        onClose={onClose}
        toastId={t.id}
      />
    ),
  };
  return toast.custom(
    toastElements[variant] ||
      ((t) => (
        <DefaultToast
          message={message}
          toastVisible={t.visible}
          onClose={onClose}
          toastId={t.id}
        />
      )),
    _options
  );
}
