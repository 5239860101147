import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
const isProd =
  process.env.NEXT_PUBLIC_BASE_URL === 'https://www.learnwithexplore.com';
const envName = isProd ? 'production' : 'development';
if (typeof window !== 'undefined') {
  // checks that we are client-side

  // track only in production
  if (isProd) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: '/ingest',
      ui_host: 'https://us.i.posthog.com',
    });
  } else {
    posthog.init(envName, {
      autocapture: false,
      api_host: '/ingest',
      ui_host: 'http://localhost:8000',
    });
  }
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider client={posthog}>
      <PostHogAuthWrapper>{children}</PostHogAuthWrapper>
    </PostHogProvider>
  );
}

function PostHogAuthWrapper({ children }: { children: React.ReactNode }) {
  const { data, status } = useSession();
  const userInfo = data?.user;

  useEffect(() => {
    if (userInfo && status !== 'loading') {
      posthog.identify(userInfo.id, {
        email: userInfo.email,
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        role: userInfo.role,
        locale: userInfo.locale,
        hasSeenOnBoarding: userInfo.hasSeenOnBoarding,
      });
    } else if (status === 'unauthenticated') {
      posthog.reset();
    }
  }, [userInfo, status]);

  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return children;
}
