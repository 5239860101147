import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Routes } from 'lib/constants';
import axiosInstance from 'lib/react-query/axios';
import { entryTypeMessage } from 'types/models/Messages';

type CreateMessageResponse = {
  content: string;
  assignmentId: string;
  entryId: string;
  entryType: entryTypeMessage;
};

const createMessage = async ({
  assignmentId,
  content,
  entryId,
  entryType,
}: CreateMessageResponse) => {
  const { data } = await axiosInstance.post(
    Routes.API.MESSAGES.replace(':assignmentId', assignmentId),
    {
      content,
      entryId,
      entryType,
    }
  );

  return data;
};

export const useCreateMessage = () => {
  const mutation = useMutation<any, AxiosError, any, unknown>({
    mutationFn: (data: CreateMessageResponse) => createMessage(data),
    throwOnError: false,
  });

  return mutation;
};
