// reportUtils.ts

import QueryKeys from '@/lib/react-query/queryKeys';
import { Like, Message } from '@prisma/client';
import { QueryKey } from '@tanstack/react-query';
import {
  CreateSpaceReflectionReport,
  EngineeringNotebookStepsWithLike,
  MessageThreadWithMessage,
  ReflectionSectionRepsonse,
  entryTypeLike,
} from 'types/models/Assignment';
import { EnumOfTextLabels } from './notebookTemplates';

export const findStudentIndex = ({
  data,
  entry,
  isKudo,
  userId,
}: {
  data: {
    students: {
      id: string;
      firstName: string;
      lastName: string;
    }[];
  };
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport;
  isKudo: boolean;
  userId: string;
}) => {
  return data.students.findIndex(
    (student) =>
      student.id === (isKudo ? entry?.sender?.id : entry.studentId || userId)
  );
};

export const findEntryIndex = (
  entries: EngineeringNotebookStepsWithLike[],
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport
) => {
  return entries.findIndex((e) => e.id === entry.id);
};

export const getEntryName = (key: EnumOfTextLabels) => {
  if (key === 'Notebook_ProblemPage_CriteriaTitle') return 'OBJECTIVES';
  if (key === 'Notebook_ProblemPage_ConstraintTitle') return 'CONSTRAINTS';
  if (
    [
      'Notebook_ResultsPage_ResultPrompt1Title',
      'Notebook_ResultsPage_ResultPrompt2Title',
      'Notebook_ResultsPage_ResultPrompt3Title',
    ].includes(key)
  )
    return 'testingResults';

  return 'kudosAndReflections';
};

export const reflectionsKeys = new Set([
  'Notebook_ReflectPage_ReflectPrompt1',
  'Notebook_ReflectPage_ReflectPrompt2',
  'Notebook_ReflectPage_ReflectPrompt3',
  'Notebook_ReflectPage_ReflectPrompt4',
  'Notebook_ReflectPage_ReflectPrompt5',
  'Notebook_ReflectPage_ReflectPrompt6',
]);

export const reportKeys = new Set([
  'Notebook_ResultsPage_ResultPrompt1Title',
  'Notebook_ResultsPage_ResultPrompt2Title',
  'Notebook_ResultsPage_ResultPrompt3Title',
  'Notebook_ResultsPage_Image',
]);

/**
 * Gets the query key based on the provided key, user ID, and assignment type.
 * @param key - The key value.
 * @param userId - The ID of the user.
 * @param assignmentType - The type of the assignment.
 * @returns The corresponding query key.
 * @throws Error if the key is invalid.
 */
export const getQueryKeyFromKey = ({
  key,
  userId,
  assignmentType,
}: {
  key: string;
  userId: string;
  assignmentType: 'CREATE_SPACE' | 'CHALLENGES';
}): QueryKey => {
  // If the key is a reflection key, return the corresponding reflection report query key
  if (key === 'Notebook_ReflectPage_TabLabel') {
    return assignmentType === 'CREATE_SPACE'
      ? [QueryKeys.CREATE_SPACE_ASSIGNMENT_REFLECTIONS_REPORT, userId]
      : [QueryKeys.CHALLENGES_ASSIGNMENT_REFLECTIONS_REPORT, userId];
  }

  // If the key is a report key, return the corresponding report query key
  if (reportKeys.has(key)) {
    return assignmentType === 'CREATE_SPACE'
      ? [QueryKeys.CREATE_SPACE_REPORT, userId]
      : [QueryKeys.CHALLENGES_ASSIGNMENT_TESTING_RESULTS_REPORT, userId];
  }

  // If the key is an objective or constraint key, return the corresponding objective or constraint query key
  switch (key) {
    case 'Notebook_ProblemPage_ConstraintTitle':
      return assignmentType === 'CREATE_SPACE'
        ? [QueryKeys.CREATE_SPACE_ASSIGNMENT_CONSTRAINTS_REPORT, userId]
        : [QueryKeys.CHALLENGES_ASSIGNMENT_CONSTRAINTS_REPORT, userId];
    case 'Notebook_ProblemPage_CriteriaTitle':
      return assignmentType === 'CREATE_SPACE'
        ? [QueryKeys.CREATE_SPACE_ASSIGNMENT_CRITERIAS_REPORT, userId]
        : [QueryKeys.CHALLENGES_ASSIGNMENT_CRITERIAS_REPORT, userId];
    default:
      throw new Error('Invalid key');
  }
};

/**
 * Gets the query key based on the provided entry type, user ID, and assignment type.
 * @param entry - The entry object.
 * @param userId - The ID of the user.
 * @param assignmentType - The type of the assignment.
 * @returns The corresponding query key.
 * @throws Error if the entry type is invalid.
 */
export const getQueryKeyFromEntryType = ({
  entry,
  userId,
  assignmentType,
}: {
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport;
  userId: string;
  assignmentType: 'CREATE_SPACE' | 'CHALLENGES';
}): QueryKey => {
  if (entry.key) {
    return getQueryKeyFromKey({
      key: entry.key,
      assignmentType: assignmentType,
      userId,
    });
  }

  // If the entry has a sender or type or isPositive, it is either a kudo, reflection, or feeling and belongs to the reflection section
  if (entry.sender || entry.isPositive || entry.type) {
    return assignmentType === 'CREATE_SPACE'
      ? [QueryKeys.CREATE_SPACE_ASSIGNMENT_REFLECTIONS_REPORT, userId]
      : [QueryKeys.CHALLENGES_ASSIGNMENT_REFLECTIONS_REPORT, userId];
  }

  // If the entry has a signedImageUrl, it is a testing result
  if (entry.signedImageUrl) {
    return assignmentType === 'CREATE_SPACE'
      ? [QueryKeys.CREATE_SPACE_REPORT, userId]
      : [QueryKeys.CHALLENGES_ASSIGNMENT_TESTING_RESULTS_REPORT, userId];
  }

  throw new Error('Invalid entry type');
};

/**
 * Updates the message data based on the provided entry, message thread, and assignment type.
 * @param oldData - The old data object.
 * @param entry - The entry object to update.
 * @param entryType - The type of the entry.
 * @param newMessageThread - The new message thread to add.
 * @param userId - The ID of the user.
 * @param assignmentType - The type of the assignment.
 * @returns The updated data object.
 */
export const updateMessageData = ({
  oldData,
  entry,
  entryType,
  newMessageThread,
  userId,
  assignmentType,
}: {
  oldData: any;
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport;
  entryType: entryTypeLike;
  newMessageThread?: MessageThreadWithMessage;
  userId: string;
  assignmentType: 'CREATE_SPACE' | 'CHALLENGES';
}) => {
  const isKudo = entryType === 'kudo';
  const isImage = entryType === 'image';
  const entryName = isImage ? 'image' : getEntryName(entry.key as string);
  const newData = { ...oldData };

  const updateTestingResultsMessageThread = (
    newData: any,
    iteration: number,
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport,
    newMessageThread: MessageThreadWithMessage | undefined,
    assignmentType: 'CREATE_SPACE' | 'CHALLENGES'
  ) => {
    if (assignmentType === 'CREATE_SPACE') {
      // Find the index of the student in the testing results array, if its an iamge entry, we use the userId, otherwise we use the studentId
      const testingResultsStudentIndex = newData.testingResults[
        iteration
      ].findIndex(
        (student: { id: string; firstName: string; lastName: string }) =>
          student.id === entry.studentId || student.id === entry.userId
      );

      // Find the index of the entry itself in the student's testing results entries array
      const entryIndex = findEntryIndex(
        newData.testingResults[iteration][testingResultsStudentIndex].entries,
        entry
      );

      // Update the message thread
      newData.testingResults[iteration][testingResultsStudentIndex].entries[
        entryIndex
      ].messageThread = [newMessageThread];
    } else {
      // Find the index of the student in the testing results array, if its an iamge entry, we use the userId, otherwise we use the studentId
      const testingResultsStudentIndex = newData.testingResults.findIndex(
        (student: { studentId: string }) =>
          student.studentId === entry.studentId ||
          student.studentId === entry.userId
      );

      // Find the index of the entry itself in the student's testing results entries array
      const entryIndex = findEntryIndex(
        newData.testingResults[testingResultsStudentIndex].entries[iteration],
        entry
      );

      // Update the message thread
      newData.testingResults[testingResultsStudentIndex].entries[iteration][
        entryIndex
      ].messageThread = [newMessageThread];
    }
  };

  const updateKudosAndReflectionsMessageThread = (
    newData: any,
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport,
    newMessageThread: MessageThreadWithMessage | undefined,
    assignmentType: 'CREATE_SPACE' | 'CHALLENGES'
  ) => {
    if (assignmentType === 'CREATE_SPACE') {
      const kudosAndReflectionsIndex = findEntryIndex(
        newData.kudosFeelingsAndReflections,
        entry
      );
      newData.kudosFeelingsAndReflections[
        kudosAndReflectionsIndex
      ].messageThread = [newMessageThread];
    } else {
      let isUpdated = false;
      for (const page of newData.pages) {
        for (const reflectionEntry of page.reflectionSection) {
          if (reflectionEntry.id === entry.id) {
            reflectionEntry.messageThread = [newMessageThread];
            isUpdated = true;
            break;
          }
        }
        if (isUpdated) {
          break;
        }
      }
    }
  };
  const updateObjectivesAndConstraintsMessageThread = (
    newData: any,
    entry: EngineeringNotebookStepsWithLike & {
      signedImageUrl?: string;
    } & CreateSpaceReflectionReport,
    newMessageThread: MessageThreadWithMessage | undefined,
    isKudo: boolean,
    userId: string
  ) => {
    const objectivesOrConstraintsStudentIndex = findStudentIndex({
      data: newData,
      entry,
      isKudo,
      userId,
    });
    const objectivesOrConstraintsEntryIndex = findEntryIndex(
      newData.students[objectivesOrConstraintsStudentIndex]
        .engineeringNotebookStep,
      entry
    );
    newData.students[
      objectivesOrConstraintsStudentIndex
    ].engineeringNotebookStep[objectivesOrConstraintsEntryIndex].messageThread =
      [newMessageThread];
  };

  switch (entryName) {
    case 'image':
    case 'testingResults': {
      const iteration = entry.iteration;
      updateTestingResultsMessageThread(
        newData,
        iteration,
        entry,
        newMessageThread,
        assignmentType
      );
      return newData;
    }
    case 'kudosAndReflections': {
      updateKudosAndReflectionsMessageThread(
        newData,
        entry,
        newMessageThread,
        assignmentType
      );
      return newData;
    }
    case 'OBJECTIVES':
    case 'CONSTRAINTS': {
      if (assignmentType === 'CREATE_SPACE') {
        updateObjectivesAndConstraintsMessageThread(
          newData,
          entry,
          newMessageThread,
          isKudo,
          userId
        );
      }
      return newData;
    }
    default:
      return newData;
  }
};

/**
 * Updates the like data in the message thread based on the provided entry, like information, operation, and assignment type.
 * @param oldData - The old data object.
 * @param entry - The entry object to update.
 * @param entryType - The type of the entry.
 * @param like - The like information.
 * @param operation - The operation to perform ('add' or 'delete').
 * @param messageThreadOriginalEntry - The original entry associated with the message thread.
 * @param isImage - Indicates if the entry is an image.
 * @param assignmentType - The type of the assignment.
 * @returns The updated data object.
 */
export const updateLikeDataInMessageThread = ({
  oldData,
  entry,
  entryType,
  like,
  operation,
  messageThreadOriginalEntry,
  isImage,
  assignmentType,
}: {
  isImage?: boolean;
  oldData: any;
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
    userId?: string;
  } & CreateSpaceReflectionReport;
  entryType: entryTypeLike;
  like: Like[];
  operation: 'add' | 'delete';
  messageThreadOriginalEntry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
  } & CreateSpaceReflectionReport;
  assignmentType: 'CHALLENGES' | 'CREATE_SPACE';
}): any => {
  const entryName = isImage
    ? 'image'
    : getEntryName(messageThreadOriginalEntry.key as string);

  const newData = { ...oldData };

  const updateLikeInMessageThread = (
    messageThread: MessageThreadWithMessage,
    messageIndex: number
  ): void => {
    if (operation === 'add') {
      if (!messageThread.messages[messageIndex].like) {
        messageThread.messages[messageIndex].like = like;
      } else {
        messageThread.messages[messageIndex].like.push(like[0]);
      }
    } else {
      messageThread.messages[messageIndex].like = [];
    }
  };

  const findMessageIndex = (
    messageThread: MessageThreadWithMessage
  ): number => {
    return messageThread.messages.findIndex(
      (message: Message) =>
        message.id ===
        (operation === 'add' ? like?.[0]?.messageId : (like as any)?.messageId)
    );
  };

  // Determine which section the entry belongs to based on entryType
  switch (entryName) {
    case 'image':
    case 'testingResults': {
      const iterationIndex = messageThreadOriginalEntry.iteration;

      const studentIndex =
        assignmentType === 'CHALLENGES'
          ? newData.testingResults.findIndex(
              (student: { studentId: string }) =>
                isImage
                  ? student.studentId === messageThreadOriginalEntry.userId
                  : student.studentId === entry.studentId ||
                    student.studentId === messageThreadOriginalEntry.studentId
            )
          : newData.testingResults[iterationIndex].findIndex(
              (student: { id: string; firstName: string; lastName: string }) =>
                isImage
                  ? student.id === messageThreadOriginalEntry.userId
                  : student.id === entry.studentId ||
                    student.id === messageThreadOriginalEntry.studentId
            );

      const entryIndex = findEntryIndex(
        assignmentType === 'CHALLENGES'
          ? newData.testingResults[studentIndex].entries[iterationIndex]
          : newData.testingResults[iterationIndex][studentIndex].entries,
        messageThreadOriginalEntry
      );

      const messageThread =
        assignmentType === 'CHALLENGES'
          ? newData.testingResults[studentIndex].entries[iterationIndex][
              entryIndex
            ].messageThread[0]
          : newData.testingResults[iterationIndex][studentIndex].entries[
              entryIndex
            ].messageThread[0];

      const messageIndex = findMessageIndex(messageThread);

      if (messageIndex !== -1) {
        updateLikeInMessageThread(messageThread, messageIndex);
      }

      return newData;
    }

    case 'kudosAndReflections': {
      if (assignmentType === 'CHALLENGES') {
        let isUpdated = false;

        for (const page of newData.pages) {
          for (const reflectionEntry of page.reflectionSection) {
            if (reflectionEntry.id === messageThreadOriginalEntry.id) {
              const messageThread = reflectionEntry.messageThread[0];
              const messageIndex = findMessageIndex(messageThread);

              if (messageIndex !== -1) {
                updateLikeInMessageThread(messageThread, messageIndex);
                isUpdated = true;
                break;
              }
            }
          }

          if (isUpdated) {
            break;
          }
        }
      } else {
        const kudosAndReflectionsEntryIndex = findEntryIndex(
          newData.kudosFeelingsAndReflections,
          messageThreadOriginalEntry
        );

        const messageThread =
          newData.kudosFeelingsAndReflections[kudosAndReflectionsEntryIndex]
            .messageThread[0];
        const messageIndex = findMessageIndex(messageThread);

        if (messageIndex !== -1) {
          updateLikeInMessageThread(messageThread, messageIndex);
        }
      }

      return newData;
    }

    case 'OBJECTIVES':
    case 'CONSTRAINTS': {
      if (assignmentType === 'CREATE_SPACE') {
        const studentIndex = findStudentIndex({
          data: newData,
          entry: messageThreadOriginalEntry,
          isKudo: entryType === 'kudo',
          userId: messageThreadOriginalEntry.studentId,
        });

        const engineeringNotebookentryIndex = findEntryIndex(
          newData.students[studentIndex].engineeringNotebookStep,
          messageThreadOriginalEntry
        );

        const messageThread =
          newData.students[studentIndex].engineeringNotebookStep[
            engineeringNotebookentryIndex
          ].messageThread[0];
        const messageIndex = findMessageIndex(messageThread);

        if (messageIndex !== -1) {
          updateLikeInMessageThread(messageThread, messageIndex);
        }
      }

      return newData;
    }

    default:
      return newData;
  }
};

interface UpdateLikeDataProps {
  oldData: any;
  entry: EngineeringNotebookStepsWithLike & {
    signedImageUrl?: string;
    userId?: string;
  } & CreateSpaceReflectionReport;
  entryType: entryTypeLike;
  like: Like[];
  operation: 'add' | 'delete';
  assignmentType: 'CREATE_SPACE' | 'CHALLENGES';
  userId?: string;
}

export const updateLikeData = ({
  oldData,
  entry,
  entryType,
  like,
  operation,
  assignmentType,
  userId,
}: UpdateLikeDataProps) => {
  const isKudo = entryType === 'kudo';
  const isImage = entryType === 'image';
  const entryName = isImage ? 'image' : getEntryName(entry.key as string);
  const newData = { ...oldData };

  const updateLikeInEntry = (entries: any[], entryIndex: number) => {
    if (operation === 'add') {
      entries[entryIndex].like.push(like[0]);
    } else {
      entries[entryIndex].like = [];
    }
  };

  switch (entryName) {
    case 'image':
    case 'testingResults': {
      const iterationIndex = entry.iteration;

      if (assignmentType === 'CREATE_SPACE') {
        const studentIndex = newData.testingResults[iterationIndex].findIndex(
          (student: { id: string; firstName: string; lastName: string }) =>
            student.id === entry.studentId || student.id === entry.userId
        );
        const entryIndex = findEntryIndex(
          newData.testingResults[iterationIndex][studentIndex].entries,
          entry
        );
        updateLikeInEntry(
          newData.testingResults[iterationIndex][studentIndex].entries,
          entryIndex
        );
      } else {
        const studentIndex = newData.testingResults.findIndex(
          (student: { studentId: string }) =>
            student.studentId === entry.studentId
        );
        const entryIndex = findEntryIndex(
          newData.testingResults[studentIndex].entries[iterationIndex],
          entry
        );
        updateLikeInEntry(
          newData.testingResults[studentIndex].entries[iterationIndex],
          entryIndex
        );
      }
      return newData;
    }

    case 'kudosAndReflections': {
      if (assignmentType === 'CREATE_SPACE') {
        const kudoIndex = findEntryIndex(
          newData.kudosFeelingsAndReflections,
          entry
        );
        updateLikeInEntry(newData.kudosFeelingsAndReflections, kudoIndex);
      } else {
        const newReflectionSection = oldData.pages.map(
          (page: ReflectionSectionRepsonse) => {
            const newPage = { ...page };
            const entryIndex = newPage.reflectionSection.findIndex(
              (reflectionEntry) => reflectionEntry.id === entry.id
            );

            if (entryIndex !== -1) {
              updateLikeInEntry(newPage.reflectionSection, entryIndex);
            }

            return newPage;
          }
        );
        return {
          pages: newReflectionSection,
          pageParams: oldData.pageParams,
        };
      }
      return newData;
    }

    case 'OBJECTIVES':
    case 'CONSTRAINTS': {
      if (assignmentType === 'CREATE_SPACE') {
        const objectivesOrConstraintsStudentIndex = findStudentIndex({
          data: newData,
          entry,
          isKudo,
          userId: entry.studentId || userId || '',
        });
        const objectivesOrConstraintsEntryIndex = findEntryIndex(
          newData.students[objectivesOrConstraintsStudentIndex]
            .engineeringNotebookStep,
          entry
        );
        updateLikeInEntry(
          newData.students[objectivesOrConstraintsStudentIndex]
            .engineeringNotebookStep,
          objectivesOrConstraintsEntryIndex
        );
      }
      return newData;
    }

    default:
      return newData;
  }
};
